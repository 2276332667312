import React, { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

//fetch
import {
  AccessDenied,
  deleteData,
  getAccountHandlersList,
  getCurrentUser,
  getData,
  postData,
  postGetData,
  postUploadData,
  putData,
  resetPassword,
  updateSubscription,
} from "../../../api/index";

//components
import Button from "../../../components/Form/Button";
import TextArea from "../../../components/Form/TextArea";
import Loader from "../../../components/Loader";
import FormModal from "../../../components/Modal";

// custom components
import {
  RenderInput,
  RenderMultiSelect,
  RenderPhoneInput,
  RenderSelect,
} from "../../Customer/CustomerForm/customComponents/inputGroups";

//validate
import { errorCheck } from "./brandErrorCheck";

//scss
import { BsFillEyeFill } from "react-icons/bs";
import lockIcon from "../../../assets/images/lock1_1.svg";
import { FaPen, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../../components/Navbar";
import MainTable from "../../../components/Table";
import SingleImageUpload from "../../../components/imageUpload/singleImageUpload";
import {
  convertIntoNameLabelObject,
  labelToName,
  nameToLabel,
} from "../../../utilities/commonFunction";
import BrandPageForm from "../../Customer/CustomerForm/brandPageForm";
import BuyerPocModal from "../../Customer/CustomerForm/poc/pocModal/buyerPocModal";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";
import { updateComapanyId } from "../../Login/reducer";
import "../style.scss";
import CustomerTable from "./customerTable";
import "./index.scss";
import SubscriptionRenew from "../../../components/subscriptionRenew/brandSubscriptionRenew";
import Select from "../../../components/Form/Select";
import {
  brandTrancationOptions,
  demandAndPropertyTransactionTypes,
  getTransactionOptions,
} from "../../../utilities/transactionOptions";
import { ENTERPRISEAGENT } from "../../../custom/constants";
import { CustomerDeleteWarningPopUp } from "../../Customer/CustomerTable/CustomerDeleteWarningPopUp";

const BrandCompany = (props) => {
  const dispatch = useDispatch();

  let location = useLocation();
  const navigate = useNavigate();
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subBrand, setSubBrand] = useState(true);
  const [successModel, setSuccessModel] = useState(false);
  const [accountHandler, setAccountHandler] = useState("");
  const [accountHandlerList, setAccountHandlerList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [contactSuppliersShow, setContactSuppliersShow] = useState(false);
  const [propertyDemandInput, setPropertyDemandInput] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [pocType, setPocType] = useState("");
  const [companyId, setCompanyId] = useState("");

  //Delete
  const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
  const [activePocId, setActivePocId] = useState("");

  // Subscription Edit
  const [subscrptionEdit, setSubscrptionEdit] = useState(false);
  const [initialSubscriptionData, setInitialSubscriptionData] = useState({});
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");

  const [initLoading, setInitLoading] = useState(false);

  //location state
  const id = location.state ? location.state.id : "";

  const edit =
    useSelector((state) => state.login.companyFormStatus) === "edit"
      ? true
      : false;
  const view =
    useSelector((state) => state.login.companyFormStatus) === "view"
      ? true
      : false;
  const access = {
    editCompanyBuyer: loginPermission?.includes(menuId.editCompanyBuyer),
    addCompanyBuyer: loginPermission?.includes(menuId?.addCompanyBuyer),
    viewCompanyBuyer: loginPermission?.includes(menuId?.viewCompanyBuyer),
    deleteBrandPoc: loginPermission?.includes(menuId?.deleteBuyerCustomers),
  };
  let customerView =
    access?.addCompanyBuyer ||
    access?.editCompanyBuyer ||
    access?.viewCompanyBuyer;
  const [customerDetails, setCustomerDetails] = useState({
    brandNameRequired: "",
    companyName: "",
    cities: [],
    state: [],
    region: [],
    propertyType: [],
    transactionType: [],
    logo: "",
    aboutCompany: "",
  });

  const [subscription, setSubscription] = useState({
    propertyOrDemandSelect: "",
    propertyOrDemand: "",
    validityDays: "",
    contactSuppliersSelect: "",
    contactSuppliers: "",
    emailtoSuppliers: "",
    userDashboard: "Yes",
    alert: "",
    featuredDemand: "",
    assistedUploading: "",
    homePageSlotAndBanner: "",
    relationshipManager: "",
    rm: "",
  });

  //select options list data
  const [regionList, setRegionList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [rmList, setRmList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [transactionTypeList, setTransactionTypeList] = useState([]);

  const transactionArray = [
    { value: "Lease", label: "Lease" },
    { value: "Revenue Share", label: "Revenue Share" },
    { value: "MG Revenue Share", label: "MG Revenue Share" },
    { value: "Sales", label: "Sales" },
  ];
  //imageupload state
  const [logoName, setLogoName] = useState([]);
  const [apiErrorData, setApiErrorData] = useState("");
  const [listData, setListData] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [pocId, setPocId] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  //pagination
  const [offset, setOffset] = useState(props.offSet ? props.offSet : 1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  //error object
  const [errorObject, setErrorObject] = useState({});

  // reset password
  const [passwordResetWarningPopup, setPasswordResetWarningPopup] =
    useState(false);
  const [currentUserId, setCurrentUserId] = useState("");

  let brandID = useSelector((state) => state.login.companyId);
  const customerArr = [
    {
      name: "Company name*",
      key: "companyName",
      type: "input",
      inputType: "text",
      placeholder: "Enter company name",
    },
    {
      name: "Show brand name*",
      key: "brandNameRequired",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Select Region*",
      key: "region",
      type: "multiSelect",
      option: regionList,
      preview: false,
    },
    {
      name: "Select state*",
      key: "state",
      type: "multiSelect",
      option: stateList,
      preview: false,
    },
    {
      name: "Select cities*",
      key: "cities",
      type: "multiSelect",
      option: cityList,
      preview: false,
    },
    {
      name: "Property type*",
      key: "propertyType",
      type: "multiSelect",
      option: propertyTypeList,
      preview: false,
    },
    {
      type: "logo",
    },
    {
      name: "Transaction type*",
      key: "transactionType",
      type: "multiSelect",
      option: transactionTypeList,
      preview: false,
    },
    {
      name: "About Company*",
      key: "aboutCompany",
      type: "textArea",
      inputType: "text",
      placeholder: "Enter about brand",
    },
  ];
  const [customerArray, setCustomerArray] = useState(customerArr);
  const subscriptionArray = [
    {
      name: "No. of demands *",
      key: "propertyOrDemandSelect",
      subKey: "propertyOrDemand",
      type: "select",
      option: ["Unlimited", "Enter number"],
    },
    {
      name: "No. of demands *",
      key: "propertyOrDemand",
      subKey: "propertyOrDemandSelect",
      type: "input",
      inputType: "number",
      placeholder: "Enter no. of demands",
    },
    {
      name: "Validity days *",
      key: "validityDays",
      type: "input",
      inputType: "number",
      // option: ["30 days", "60 days", "90 days"],
    },
    {
      name: "Email to suppliers *",
      key: "emailtoSuppliers",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Contact suppliers *",
      key: "contactSuppliersSelect",
      subKey: "contactSuppliers",
      type: "select",
      option: ["Unlimited", "Enter number"],
    },
    {
      name: "Contact suppliers *",
      key: "contactSuppliers",
      subKey: "contactSuppliersSelect",
      type: "input",
      inputType: "number",
      placeholder: "Enter number",
    },
    {
      name: "User dashboard *",
      key: "userDashboard",
      type: "input",
      inputType: "text",
      placeholder: "eg:Multi user dashboard",
    },
    {
      name: "Property Alerts *",
      key: "alert",
      type: "select",
      option: ["Instant", "Daily", "Monthly", "Weekly"],
    },
    {
      name: "Top brand slot *",
      key: "homePageSlotAndBanner",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Assisted uploading *",
      key: "assistedUploading",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Featured demands *",
      key: "featuredDemand",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Relationship manager *",
      key: "relationshipManager",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Select RM *",
      key: "rm",
      type: "select",
      option: rmList,
    },
  ];
  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            label: data?.name,
            value: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };
  useEffect(() => {
    const customerArrayUpdated = customerArray.map((element, index) => {
      return element.key === "propertyType"
        ? { ...element, option: propertyTypeList }
        : element;
    });
    setCustomerArray(customerArrayUpdated);
  }, [propertyTypeList]);

  const getRegions = async () => {
    let url = "/region/regions";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      let regionArray = nameToLabel(data);
      setRegionList(regionArray);
    } else {
      setRegionList([]);
    }
  };

  useEffect(() => {
    const dularr = { ...customerDetails };
    dularr.propertyTypes = customerDetails.propertyType;
    // let transactionOptions = getTransactionOptions(
    //   dularr,
    //   brandTrancationOptions
    // );
    let transactionOptions = getTransactionOptions(
      dularr,
      demandAndPropertyTransactionTypes
    );
    // console.log({transactionOptions: nameToLabel(transactionOptions)})
    // console.log({transaction: customerDetails?.transactionType})
    if (customerDetails?.transactionType) {
      const filteredTransactionArray = customerDetails?.transactionType.filter(
        (item1) => {
          return nameToLabel(transactionOptions).some(
            (item2) => item2.value === item1.value
          );
        }
      );
      setCustomerDetails({
        ...customerDetails,
        transactionType: filteredTransactionArray,
      });
    }
    // const customerArrayUpdated = customerArray.map((element, index) => {
    //   if (element.key === "transactionType") {
    //   }
    //   return element.key === "transactionType"
    //     ? { ...element, option: nameToLabel(transactionOptions) }
    //     : element;
    // });
    // setCustomerArray(customerArrayUpdated);
    setTransactionTypeList(nameToLabel(transactionOptions));
  }, [customerDetails?.propertyType]);

  useEffect(() => {
    if (localStorage.getItem("subscriptionEdit") === "true") {
      setSubscrptionEdit(true);
    }
    localStorage.setItem("subscriptionEdit", JSON.stringify(false));
  }, []);

  const handleSubscriptionEdit = async () => {
    if (subscription.contactSuppliersSelect === "Unlimited") {
      subscription.contactSuppliers = "Unlimited";
    }
    if (subscription.propertyOrDemandSelect === "Unlimited") {
      subscription.propertyOrDemand = "Unlimited";
    }

    if (subscription?.relationshipManager === "No") {
      delete subscription?.rm;
    }
    let errors = await errorCheck(customerDetails, subscription);
    if (
      !errors["validityDays"] &&
      parseInt(subscription?.validityDays) <
        parseInt(initialSubscriptionData?.validityDays)
    ) {
      errors["validityDays"] =
        "Subscription validity must be greater that current validity";
    }
    if (Object.keys(errors).length === 0) {
      delete subscription.contactSuppliersSelect;
      delete subscription.propertyOrDemandSelect;
      setLoading(true);
      updateSubscription(brandID, { ...subscription }, "Brand").then((res) => {
        setLoading(false);
        setSubscrptionEdit(false);
      });
    } else {
      setErrorObject(errors);
    }
  };

  const handleViewPoc = (row, type) => {
    setPocType(type);
    setOpenModal(true);
    setPocId(row);
  };
  const onActiveStatusChange = (pocId, active) => {
    setActiveStatus(active);
    setCustomerId(pocId);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this POC");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this POC");
    }
  };

  function userButtonFormatterBuyer(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center ">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewPoc(row, "view");
            }}
          >
            <BsFillEyeFill />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              cursor: row?.designation !== "Account Handler" && "pointer",
            }}
            onClick={
              view || row?.designation === "Account Handler"
                ? null
                : () => {
                    handleViewPoc(row, "edit");
                  }
            }
          >
            <FaPen />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
            disabled={
              view || row?.designation === "Account Handler" ? true : false
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            onClick={(e) => {
              setActivePocId(row?._id);
              setDeleteWarningPopup(true);
            }}
            // disabled={props?.access?.viewCompanySeller ? false : true}
            disabled={access?.deleteBrandPoc ? false : true}
          >
            <FaTrash />
          </Button>
        </div>
      </>
    );
  }
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "primary",
      text: "Primary POC",
    },
    {
      dataField: "name",
      text: "POC Name",
    },
    {
      dataField: "designation",
      text: "Designation",
    },
    {
      dataField: "email",
      text: "Email ID",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBuyer,
    },
  ];

  const getRmList = () => {
    let url =
      "/user/getAllSubAdmin?pageNo=0&limit=0&role=Relationship Manager&type=rm";
    getData(url, {}, {}).then((response) => {
      setRmList(response?.data?.subAdminList);
    });
  };

  useEffect(() => {
    if (subscription?.relationshipManager === "Yes") {
      getRmList();
    }
  }, [subscription?.relationshipManager]);

  // Getting Account Handler List
  const getAcHanList = async () => {
    const response = await getAccountHandlersList();
    if (response?.data?.getAllAccountHandler) {
      setAccountHandlerList(response.data.getAllAccountHandler);
    }
  };
  useEffect(() => {
    if (successModel) {
      getAcHanList();
    } else {
      setAccountHandlerList([]);
      setAccountHandler("");
    }
  }, [successModel]);

  const getPocList = () => {
    if (companyId || id || brandID) {
      let url = `/company/poc/${brandID}?pageNo=${offset}&limit=${perPage}`;
      getData(url, {}, {}).then((response) => {
        if (response.status) {
          setInitLoading(false);
          setPageCount(Math.ceil(response.data.totalDoc / perPage));
          setTotalCount(response.data.totalDoc);
          setListData(response.data.companyPocs);
        } else {
          setInitLoading(false);
          setPageCount(0);
          setListData([]);
        }
      });
    }
  };
  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
    };
    let url = `/company/updatePoc/${brandID}/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getPocList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  // PASSWORD RESET
  const handleResetPassword = (row) => {
    setCurrentUserId(row._id);
    setPasswordResetWarningPopup(true);
    setWarningMessage(
      "Are you sure, do you want to reset the login credentials?"
    );
  };

  const passwordResetWarningYesClick = () => {
    resetPassword(currentUserId).then(() => {
      setPasswordResetWarningPopup(false);
    });
  };

  const handlePasswordResetWarningClose = () => {
    setPasswordResetWarningPopup(false);
  };

  const handleWarningClose = (type) => {
    if (type === "delete") {
      setDeleteWarningPopup(false);
    } else {
      setWarningPopup(false);
    }
  };
  const deleteWarningYesClick = () => {
    let url = `/brand/deleteBrandPoc/${activePocId}`;
    deleteData(url, {}, {}).then((response) => {
      if (response.status) {
        setDeleteWarningPopup(false);
        getPocList();
      } else {
        setDeleteWarningPopup(false);
      }
    });
  };

  const updateCityList = (array) => {
    let cityArray = [];
    array?.map((has) => {
      customerDetails?.cities.map((el) => {
        if (el?.value === has?.value) {
          cityArray.push(el);
        }
        return el;
      });
      return has;
    });
    setCustomerDetails((prev) => ({ ...prev, cities: cityArray }));
  };

  const getCities = (stateId, onChangeState) => {
    setInitLoading(true);
    let url = "/country/getCitiesByState";
    let listedId = stateId.map((item) => item.value);
    let payload = { id: listedId };
    postGetData(url, {}, payload).then((response) => {
      setInitLoading(false);
      if (response?.status) {
        let cityArray = [];
        response?.data?.map((data) => {
          cityArray.push({
            label: data?.name,
            value: data?._id,
          });
          return data;
        });
        setCityList(cityArray);
        if (onChangeState) {
          updateCityList(cityArray);
        }
      }
    });
  };

  const getImageData = (customerData) => {
    if (edit || view) {
      let logoUrl = customerData.logo ? customerData.logo : {};

      setLogoName([
        { name: logoUrl ? (logoUrl.fileName ? logoUrl.fileName : "") : "" },
      ]);
    }
  };
  const getCustomerData = () => {
    let url = `/company/${brandID}`;
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        getImageData(response.data);
        let customerObj = {};
        let subscriptionDetail = response.data.subscriptionPlanId;
        // subscriptionDetail.rm = response?.data?.rm
        Object.entries(customerDetails).map(([k, value]) => {
          Object.assign(customerObj, { [k]: response.data[k] });
        });
        setSubBrand(response.data.subBrands);
        setCustomerId(response.data._id);
        let cityArray = nameToLabel(customerObj?.cities);
        let stateArray = nameToLabel(customerObj?.state);
        let regionArray = nameToLabel(customerObj?.region);
        let propertyArray = nameToLabel(customerObj?.propertyType);
        let transactionArray = convertIntoNameLabelObject(
          customerObj.transactionType ? customerObj.transactionType : []
        );
        customerObj.brandNameRequired =
          customerObj.brandNameRequired === true ? "Yes" : "No";
        customerObj.cities = cityArray;
        customerObj.state = stateArray;
        customerObj.region = regionArray;
        customerObj.propertyType = propertyArray;
        customerObj.transactionType = transactionArray;
        // console.log({ customerObj });
        // setCustomerDetails({ ...customerObj });

        if (response?.data?.isSuccessModel) {
          setSuccessModel(true);
        } else {
          setAccountHandler("");
        }

        if (response?.data?.accountHandlerId !== "null") {
          setAccountHandler(response?.data?.accountHandlerId);
        } else {
          setAccountHandler("");
        }

        if (subscriptionDetail.contactSuppliers === "Unlimited") {
          subscriptionDetail.contactSuppliersSelect = "Unlimited";
        } else {
          subscriptionDetail.contactSuppliersSelect = "Enter number";
          setContactSuppliersShow(true);
        }
        if (subscriptionDetail.propertyOrDemand === "Unlimited") {
          subscriptionDetail.propertyOrDemandSelect = "Unlimited";
        } else {
          subscriptionDetail.propertyOrDemandSelect = "Enter number";
          setPropertyDemandInput(true);
        }

        //Checking for Subscription Expiry
        const validityDays = parseInt(subscriptionDetail?.validityDays);
        const subscriptionDate = new Date(subscriptionDetail?.subscriptionDate);

        const expireDate = new Date(
          subscriptionDate.getTime() + validityDays * 24 * 60 * 60 * 1000
        );

        const currentDate = new Date();

        const hasExpired = expireDate <= currentDate;
        if (hasExpired) {
          setSubscriptionExpired(true);
        } else {
          setSubscriptionExpired(false);
        }

        const day = expireDate.getDate();
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = expireDate.getMonth();
        const year = expireDate.getFullYear();

        const formattedExpiryDate = `${day}${
          day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
        } ${monthNames[monthIndex]} ${year}`;

        if (hasExpired) {
          setSubscriptionExpiryDate(`Expired on ${formattedExpiryDate}`);
        } else {
          setSubscriptionExpiryDate(`Plan expires on ${formattedExpiryDate}`);
        }
        setCustomerDetails({ ...customerObj });
        setSubscription({ ...subscriptionDetail });
        setInitialSubscriptionData({ ...subscriptionDetail });
        getStates(regionArray, false);
        getCities(stateArray, false);
      }
    });
  };

  // useEffect(() => {
  //   console.log({ customerDetails });
  // }, [customerDetails])
  useEffect(() => {
    if (edit || view || brandID) {
      getCustomerData();
    }
  }, [edit, view]);

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  useEffect(() => {
    getPocList();
  }, [perPage, offset]);
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  const createCompanyPage = () => {
    delete subscription.contactSuppliersSelect;
    delete subscription.propertyOrDemandSelect;
    let cityArray = labelToName(customerDetails?.cities);
    let stateArray = labelToName(customerDetails?.state);
    let regionArray = labelToName(customerDetails?.region);
    let propertyArray = labelToName(customerDetails?.propertyType);
    let transactionTypeArray = labelToName(customerDetails?.transactionType);
    setCustomerDetails({
      ...customerDetails,
      subscriptionPlanId: subscription,
    });

    let dataList = {
      ...customerDetails,
      cities: cityArray,
      state: stateArray,
      region: regionArray,
      propertyType: propertyArray,
      subscriptionPlanId: subscription,
      transactionType: transactionTypeArray,
      brandNameRequired:
        customerDetails?.brandNameRequired === "Yes" ? true : false,
      // roles: role,
      type: "Brand",
      subBrands: subBrand,
      isSuccessModel: successModel,
      accountHandlerId: successModel ? accountHandler : "null",
    };
    if (dataList?.subscriptionPlanId?.relationshipManager === "No") {
      delete dataList?.subscriptionPlanId?.rm;
    }
    if (edit) {
      delete dataList.roles;
      delete dataList.type;
      delete dataList.subscriptionPlanId;
    }
    setLoading(true);
    if (edit || view || isCreated) {
      setApiErrorData("");
      putData(`/company/${brandID}`, {}, dataList).then((response) => {
        if (response.status) {
          navigate("/company");
          setIsCreated(true);
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
          setApiErrorData(response.message);
        } else {
          setLoading(false);
        }
      });
    } else {
      setApiErrorData("");
      postData("/company", {}, dataList).then((response) => {
        if (response.status) {
          setCustomerId(response.data._id);
          // localStorage.setItem("companyId", JSON.stringify(response.data._id));
          setCompanyId(response.data._id);
          dispatch(updateComapanyId(response.data._id));
          setLoading(false);
          setIsCreated(true);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
          setIsCreated(false);
          setApiErrorData(response.message);
        } else {
          setLoading(false);
          setIsCreated(false);
        }
      });
    }
  };
  const getStates = (region, onChangeRegion) => {
    let url = "/country/getStates";
    let listedId = region.map((item) => item.value);
    let payload = { regionIds: listedId };
    postGetData(url, {}, payload).then((response) => {
      if (response?.status) {
        let stateArray = [];
        response?.data?.map((data) => {
          stateArray.push({
            label: data?.name,
            value: data?._id,
          });
          return data;
        });
        setStateList(stateArray);
        if (onChangeRegion) {
          updateStateList(stateArray, region);
        }
      }
    });
  };
  const updateStateList = (array) => {
    let stateArray = [];
    array?.map((has) => {
      customerDetails?.state.map((el) => {
        if (el?.value === has?.value) {
          stateArray.push(el);
        }
        return el;
      });
      return has;
    });

    setCustomerDetails((prev) => ({ ...prev, state: stateArray }));
    getCities(stateArray, true);
  };

  const onChange = (value, key) => {
    delete errorObject[key];
    if (key === "contactPerson") {
      setCustomerDetails({
        ...customerDetails,
        [key]: value.replace(/[^a-z ]/gi, ""),
      });
    } else if (key === "brandNameRequired") {
      delete errorObject["homePageSlotAndBanner"];
      if (value === "Yes") {
        setSubscription({
          ...subscription,
          homePageSlotAndBanner: "",
          companyTopBrand: true,
        });
      } else {
        let subdata = { ...subscription };
        subdata.homePageSlotAndBanner = "No";
        delete subdata.companyTopBrand;
        setSubscription(subdata);
      }

      setCustomerDetails({ ...customerDetails, [key]: value });
    } else if (key === "state") {
      if (value?.length > 0) {
        getCities(value, true);
        setCustomerDetails({ ...customerDetails, [key]: value });
      } else {
        setCityList([]);
        setCustomerDetails({ ...customerDetails, cities: [], [key]: value });
      }
    } else if (key === "region") {
      if (value?.length > 0) {
        getStates(value, true);
        setCustomerDetails({ ...customerDetails, [key]: value });
      } else {
        setStateList([]);
        setCityList([]);
        setCustomerDetails({
          ...customerDetails,
          state: [],
          cities: [],
          [key]: value,
        });
      }
    } else {
      setCustomerDetails({ ...customerDetails, [key]: value });
    }
  };

  const onChangeSubscription = (value, key) => {
    delete errorObject[key];
    let data = value;
    if (key === "relationshipManager") {
      delete errorObject["rm"];
      if (value !== "Yes") {
        setRmList([]);
      }
    } else if (key === "rm") {
      let rmObj = rmList?.filter((el) => value === el?._id);
      data = rmObj[0];
    }
    setSubscription({ ...subscription, [key]: data });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const renderTextArea = (name, key, value, error, placeholder) => {
    return (
      <TextArea
        errorMessage={error}
        placeholder={placeholder}
        label={name}
        name={name}
        disabled={view ? true : false}
        value={value}
        isInvalid={!!error}
        size={"w-80"}
        onChange={(e) => {
          onChange(e.target.value.replace("\n", ""), key);
        }}
      />
    );
  };

  const handleModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    // getAccessList();
    setPocType("");
    setPocId("");
  };
  const handleSubBrand = (val) => {
    setSubBrand(val);
  };

  const handleSuccessModel = (val) => {
    if (!val) setAccountHandler("");
    setSuccessModel(val);
  };
  const logoImageApi = (files, key) => {
    if (files.length === 0) {
      setCustomerDetails({ ...customerDetails, [key]: {} });
    }
    let data = new FormData();
    data.append("uploadfiles", files[0]);
    if (files.length > 0) {
      setInitLoading(true);

      let url = "/brand/file-upload/?type=image&module=brand";
      postUploadData(url, {}, data).then((response) => {
        if (response.status) {
          setCustomerDetails({ ...customerDetails, [key]: response.data[0] });
          setInitLoading(false);
        } else {
          setInitLoading(false);
        }
      });
    }
  };
  const handleOnSubmit = async (event) => {
    if (subscription.contactSuppliersSelect === "Unlimited") {
      subscription.contactSuppliers = "Unlimited";
    }
    if (subscription.propertyOrDemandSelect === "Unlimited") {
      subscription.propertyOrDemand = "Unlimited";
    }

    event.preventDefault();
    let errors = await errorCheck(customerDetails, subscription);
    if (successModel && (accountHandler === "" || accountHandler === null)) {
      errors["accountHandlerId"] = "Select an account handler";
    }
    if (Object.keys(errors).length === 0) {
      createCompanyPage();
    } else {
      setErrorObject(errors);
    }
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSubmit(e);
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("brandEdit"))) {
      setSubBrand(false);
      setIsCreated(true);
    }
    getRegions();
    getPropertyType();
    getPocList();
  }, []);

  return (
    <>
      {initLoading && <Loader className={"fixed"} />}

      {customerView ? (
        <>
          <NavBar />

          <div className="brandPageForm-container">
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleOnSubmit(e)}
              onKeyDown={(e) => onKeyDown(e)}
            >
              <div className="d-flex company-radio-container">
                <p>Are you opted for success model?</p>
                <Form.Check
                  inline
                  label="Yes"
                  name="successModel"
                  type="radio"
                  value={true}
                  checked={successModel === true}
                  disabled={view ? true : false}
                  id={"inline-radio-1"}
                  onChange={() => {
                    handleSuccessModel(true);
                  }}
                />
                <Form.Check
                  inline
                  label="No"
                  name="successModel"
                  type="radio"
                  value={false}
                  disabled={view ? true : false}
                  checked={successModel === false}
                  id={"inline-radio-2"}
                  onChange={() => {
                    handleSuccessModel(false);
                    // localStorage.setItem("brandEdit", JSON.stringify(false));
                    // // dispatch(updateBrandId(""))
                  }}
                />
              </div>
              {successModel && (
                <div className="row">
                  <Select
                    label="Select account handler"
                    name="accountHandler"
                    value={accountHandler}
                    disabled={location?.state?.view ? true : false}
                    onChange={(e) => {
                      setAccountHandler(e.target.value);
                      delete errorObject["accountHandlerId"];
                    }}
                    errorMessage={errorObject["accountHandlerId"]}
                    isInvalid={errorObject["accountHandlerId"] && true}
                    placeholder="Select"
                    options={accountHandlerList}
                    size={"w-40"}
                  />
                </div>
              )}

              {isCreated || edit || view ? null : (
                <>
                  <div className="d-flex company-radio-container">
                    <p>Do you have sub-brand</p>
                    <Form.Check
                      inline
                      label="Yes"
                      name="subBrands"
                      type="radio"
                      value={true}
                      checked={subBrand === true}
                      id={"inline-radio-1"}
                      onChange={() => {
                        handleSubBrand(true);
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="subBrands"
                      type="radio"
                      value={false}
                      checked={subBrand === false}
                      id={"inline-radio-2"}
                      onChange={() => {
                        handleSubBrand(false);
                        localStorage.setItem(
                          "brandEdit",
                          JSON.stringify(false)
                        );
                        // dispatch(updateBrandId(""))
                      }}
                    />
                  </div>
                </>
              )}
              {subBrand ? (
                <>
                  <div className="row">
                    {customerArr.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          {item.type === "input" && (
                            <RenderInput
                              name={item.name}
                              keyValue={item.key}
                              value={customerDetails[item.key]}
                              error={errorObject[item.key]}
                              inputType={item.inputType}
                              objName="customer"
                              placeholder={item.placeholder}
                              disabled={view ? true : false}
                              onChange={onChange}
                              edit={edit}
                            />
                          )}
                          {item.type === "phoneInput" && (
                            <RenderPhoneInput
                              name={item.name}
                              keyValue={item.key}
                              value={customerDetails[item.key]}
                              error={errorObject[item.key]}
                              inputType={item.inputType}
                              placeholder={item.placeholder}
                              disabled={view ? true : false}
                              onChange={onChange}
                            />
                          )}
                          {item.type === "textArea" &&
                            renderTextArea(
                              item.name,
                              item.key,
                              customerDetails[item.key],
                              errorObject[item.key],
                              item.placeholder
                            )}
                          {item.type === "select" && (
                            <RenderSelect
                              option={item?.option}
                              name={item.name}
                              keyValue={item.key}
                              value={customerDetails[item.key]}
                              error={errorObject[item.key]}
                              placeholder={item.placeholder}
                              disabled={
                                view ||
                                ((edit || isCreated) &&
                                  item.key === "brandNameRequired")
                                  ? true
                                  : false
                              }
                              onChange={onChange}
                              objName="customer"
                            />
                          )}
                          {item.type === "logo" && (
                            <div className="col-md-6 w-40 col-sm-12">
                              <SingleImageUpload
                                multiple={false}
                                files={logoName ? logoName : []}
                                name="logo"
                                disabled={view ? true : false}
                                id="logo"
                                imageApi={(files) =>
                                  logoImageApi(files, "logo", errorObject)
                                }
                                placeholder="Upload logo"
                                label="Upload logo *"
                                errorObject={errorObject}
                                setErrorObject={setErrorObject}
                                errorMessage={
                                  errorObject?.logo ? errorObject?.logo : ""
                                }
                                className="w-100"
                              />
                            </div>
                          )}
                          {item.type === "multiSelect" && (
                            <RenderMultiSelect
                              option={item?.option}
                              name={item.name}
                              keyValue={item.key}
                              value={customerDetails[item.key]}
                              error={errorObject[item.key]}
                              placeholder={item.placeholder}
                              onChange={onChange}
                              disabled={view ? true : false}
                              objName="customer"
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col col-6 ">
                      <div className="row">
                        <div className="col-12">
                          <label title="Subscription" className="text-heading">
                            Subscription
                          </label>

                          {!subscriptionExpired && edit && !view && (
                            <span className="text-sm text-muted ms-3">
                              {`(${subscriptionExpiryDate})`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col col-6">
                      <div className="d-flex gap-3 float-end align-items-center">
                        {subscriptionExpired
                          ? !view && (
                              <>
                                <span className="text-sm text-danger">
                                  {subscriptionExpiryDate}
                                </span>
                                <Button
                                  type="button"
                                  className="btn-color-primary d-block"
                                  onClick={(e) => {
                                    setShowRenewModal(true);
                                  }}
                                >
                                  <span>Renew Subscription</span>
                                </Button>
                              </>
                            )
                          : !subscrptionEdit &&
                            edit &&
                            !view && (
                              <Button
                                type="button"
                                className="btn-outline-color-primary d-block border-0"
                                onClick={(e) => {
                                  setSubscrptionEdit(true);
                                }}
                              >
                                <span className="me-2 text-blue ">
                                  Edit Plan
                                </span>
                                <FaPen className="text-blue" />
                              </Button>
                            )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {subscriptionArray.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          {item.type === "input" && (
                            <RenderInput
                              name={item.name}
                              keyValue={item.key}
                              value={subscription[item.key]}
                              error={errorObject[item.key]}
                              inputType={item.inputType}
                              objName="subscription"
                              onChangeSubscription={onChangeSubscription}
                              edit={edit}
                              disabled={
                                view || (!subscrptionEdit && edit)
                                  ? true
                                  : false
                              }
                              placeholder={item.placeholder}
                              subKey={item.subKey ? item.subKey : ""}
                              contactSuppliersShow={contactSuppliersShow}
                              propertyDemandInput={propertyDemandInput}
                            />
                          )}
                          {item.type === "select" && (
                            <RenderSelect
                              name={item.name}
                              keyValue={item.key}
                              option={item?.option}
                              value={subscription[item.key]}
                              error={errorObject[item.key]}
                              placeholder={item.placeholder}
                              onChangeSubscription={onChangeSubscription}
                              objName="subscription"
                              subKey={item.subKey ? item.subKey : ""}
                              rmVal={subscription?.relationshipManager}
                              disabled={
                                view || (!subscrptionEdit && edit)
                                  ? true
                                  : false
                              }
                              setContactSuppliersShow={setContactSuppliersShow}
                              setPropertyDemandInput={setPropertyDemandInput}
                              subscription={subscription}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                    <br />
                  </div>
                  <span className="error-message_span mb-3">
                    {apiErrorData}
                  </span>
                  <div className="row">
                    <div className="col col-sm-12 col-md-12 col-lg-6 align-items-center">
                      {subscrptionEdit && (
                        <div className="btn-container mt-3 mb-5 float-lg-start">
                          <>
                            <Button
                              type="button"
                              className="btn-outline-color-primary d-block"
                              onClick={(e) => {
                                setSubscription(initialSubscriptionData);
                                setSubscrptionEdit(false);
                              }}
                              disabled={!subscrptionEdit}
                            >
                              Cancel
                            </Button>
                            {view ? null : (
                              <Button
                                type="button"
                                disabled={loading || !subscrptionEdit}
                                loading={loading}
                                className="btn-color-primary d-block"
                                onClick={handleSubscriptionEdit}
                              >
                                Update Subscription
                              </Button>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                    <div className="col col-sm-12 col-md-12 col-lg-6 d-flex align-items-end justify-content-end">
                      <div className="btn-container mt-3">
                        <>
                          <Button
                            type="button"
                            className="btn-outline-color-primary d-block"
                            onClick={(e) => {
                              navigate("/company");
                            }}
                          >
                            Cancel
                          </Button>
                          {view ? null : (
                            <Button
                              type="submit"
                              disabled={loading}
                              loading={loading}
                              className="btn-color-primary d-block"
                            >
                              {edit
                                ? "Update brand page"
                                : isCreated
                                ? "Update Company"
                                : "Create Company"}
                            </Button>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <BrandPageForm
                  fromCompanyForm={true}
                  companyType={"companyNoBrand"}
                  setIsCreated={setIsCreated}
                  view={view}
                  successModel={successModel}
                  setSuccessModel={setSuccessModel}
                  accountHandler={accountHandler}
                  setAccountHandler={setAccountHandler}
                  setHandlerError={setErrorObject}
                />
              )}
            </Form>

            {(isCreated || brandID || edit || view) && subBrand ? (
              <>
                <label title="POC" className="text-heading">
                  Company Specific POC
                </label>
                <div className="d-flex justify-content-end m-2">
                  {view ? null : (
                    <Button
                      className="btn-color-primary d-block"
                      onClick={handleModal}
                    >
                      Add POC
                    </Button>
                  )}
                </div>
                {initLoading && <Loader className={"fixed"} />}
                <MainTable {...tableProps} />
                {loginPermission?.includes(menuId?.customerBuyer) ? (
                  <>
                    <label title="POC" className="text-heading mt-4">
                      Brand
                    </label>
                    <CustomerTable customer="Brand" />
                  </>
                ) : null}
              </>
            ) : null}
            <FormModal
              show={openModal}
              onClose={handleClose}
              heading={
                pocType === "view"
                  ? "View POC"
                  : pocType === "edit"
                  ? "Edit POC"
                  : "Add POC"
              }
              // customer={false}
              children={
                <BuyerPocModal
                  pocType={pocType}
                  pocEditData={pocId}
                  modKey={"companyPOC"}
                  type={"brand"}
                  onClose={handleClose}
                  pocList={getPocList}
                  id={companyId ? companyId : brandID}
                />
              }
              size={"lg"}
            />

            {warningPopup && (
              <CustomerWarningPopup
                title={"Confirmation"}
                show={warningPopup}
                message={warningMessage}
                warningYesClick={warningYesClick}
                onClose={handleWarningClose}
              />
            )}

            {deleteWarningPopup && (
              <CustomerDeleteWarningPopUp
                show={deleteWarningPopup}
                title={"Confirmation"}
                message={"Are you sure, you want to delete this POC"}
                warningYesClick={deleteWarningYesClick}
                onClose={() => handleWarningClose("delete")}
              />
            )}

            {passwordResetWarningPopup && (
              <CustomerWarningPopup
                title={"Confirmation"}
                show={passwordResetWarningPopup}
                message={warningMessage}
                warningYesClick={passwordResetWarningYesClick}
                onClose={handlePasswordResetWarningClose}
              />
            )}

            <SubscriptionRenew
              subscriptionArray={subscriptionArray}
              showRenewModal={showRenewModal}
              setShowRenewModal={setShowRenewModal}
              brandId={brandID}
              setRmList={setRmList}
              rmList={rmList}
              getCustomerData={getCustomerData}
            />
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default BrandCompany;
