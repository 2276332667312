//check for access

import { getPlanData } from "../api";

export const checkForAccess = (permissions, menuId) => {
  if (Array.isArray(permissions)) {
    return permissions.includes(menuId) ? true : false;
  }
  return false;
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};

export const stringTolabelValue = (dataArray) => {
  let array = [];
  dataArray?.map((data) => {
    array.push({
      label: data,
      value: data,
    });
    return data;
  });
  return array;
};

export const labelToName = (dataArray) => {
  let array = [];
  dataArray?.map((data) => {
    array.push({
      name: data?.label,
      _id: data?.value,
    });
    return data;
  });
  return array;
};
export const nameToLabel = (dataArray) => {

  let array = [];
  dataArray?.map((val) => {
    array.push({
      label: val?.name,
      value: val?._id ? val?._id : val?.id,
    });
    return val;
  });
  return array;
};

export const nameArrayOfObjects = (inputArray) => {
  const outputArray = inputArray.map(item => ({
    _id: item.name,
    name: item.name
  }));
  return  outputArray.length > 0 ? outputArray : []
}

export const filteredTransactionOptions = (filterArray, usersTransactionType) => {
  const filteredArray = usersTransactionType.filter((obj) => {
    return filterArray.some(
      (filterObj) => filterObj._id === obj._id && filterObj.name === obj.name
    );
  });
  return filteredArray.length > 0 ? filteredArray: [];
};

export const labelValueOfObjects = (inputArray) => {
  const outputArray = inputArray.map(item => ({
    value: item.name,
    label: item.name
  }));
  return  outputArray.length > 0 ? outputArray : []
}

export const nameValueArrayOfObjects = (inputArray) => {
  const outputArray = inputArray.map(item => ({
    value: item.name,
    name: item.name
  }));
  return  outputArray.length > 0 ? outputArray : []
}
export const convertedArrayIntoObjects = (usersTransactionType) => {
  const transformedArray = usersTransactionType.map((type) => {
    const formattedType = type.toUpperCase().replace(/\s+/g, "_");
    return {
      _id: formattedType,
      name: type,
    };
  });
  return transformedArray;
};

export const convertIntoNameLabelObject = (inputArray) => {
  const outputArray = inputArray.map((value) => ({
    label: value,
    value: value.includes(" ")
      ? value.replace(/ /g, "_").toUpperCase()
      : value.toUpperCase(),
  }));

  return outputArray;
};


export const convertIntoNameStringArray = (inputArray) => {
  const outputArray = inputArray.map((value) => {
    return value?.label
  });
  return outputArray;
};

export const truncateString = (str, num) =>
  str?.length > num ? str.slice(0, num) + "..." : str;

// To check if a value is a thruthy value
export const isTruthy = (value) => !!value;
// To check if a value is a thruthy value
export const isFalsy = (value) => !value;
export function isObjectHasValue(obj) {
  let keyValue = [];
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      keyValue.push(key);
    }
  }

  return keyValue?.length > 0 ? true : false;
}

export function isNumeric(inputString) {
  // Use a regular expression to check if the string contains only numbers
  return !/^\d+$/.test(inputString);
}

export const checkForUserSubPlan = async(id, type) => {
  let url = ""
  if(type === "property") {
    url = `/property/checkUserPlan?developerId=${id}`;
  } else {
    url = `/demand/checkUserPlan?brandId=${id}`;
  }
  const response =  await getPlanData(url, {}, {})
  return response
}


export function removeKeysFromObject(obj, keysToRemove) {
  const newObj = {...obj};
  keysToRemove.forEach(key => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key];
    }
  });
  return newObj;
}

export function removeEmptyStringValues(obj) {
  for (const key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}