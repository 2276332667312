import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavBar from "../../components/Navbar";
import "./style.scss";
import Testmonials from "./testmonials";

import { Form } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import Tab from "../../components/Tab/index";
import { AddBanner, AdminWarningPopup } from "./addBanner";
import { AddAdvertise, AdvAdminWarningPopup } from "./addAdvertise";

import FormModal from "./warning";
import { AccessDenied, deleteData, getData, putData } from "../../api";
import MainTable from "../../components/Table";
import Clientele from "./clientele/clientele";
import Statistics from "./Statistics/Statistics";
import CategoriesPage from "./categories/CategoriesPage";
import SplashScreen from "./splashScreen/SplashScreen";
import CoverImage from "./coverImage/CoverImage";

const ContentManagement = () => {
  const [contentDatas, setContentdatas] = useState("");
  const [bannerData, setbannerdata] = useState([]);
  const [bannerToggle, setBannerToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listValue, setListValue] = useState([]);
  const [contentId, setContentId] = useState("");
  const [stat, setStat] = useState(false);
  const [action, setAction] = useState("Add");
  const [bannerImageId, setBannerImageId] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [advertisementDetail, setAdvertisementDetail] = useState([]);
  const [editValue, setEditValue] = useState({
    brandId: "",
    image: "",
    brandName: "",
    type: "Developer",
  });
  const [showBanner, setShowBanner] = useState(false);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const [key, setKey] = useState("content");
  const [modalOpen, setModalOpen] = useState(false);
  const [advertiseId, setAdvertiseId] = useState("");
  const [advertiseModal, setAdvertiseModal] = useState(false);
  const [editAdvertise, setEditAdvertise] = useState({});

  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    add: loginPermission?.includes(menuId?.addContentManagement) ? true : false,
    edit: loginPermission?.includes(menuId?.editContentManagement)
      ? true
      : false,
    delete: loginPermission?.includes(menuId?.deleteContentManagement)
      ? true
      : false,
    enable: loginPermission?.includes(menuId?.contentManagementEnableOrDisable)
      ? true
      : false,
  };
  const listData = [
    {
      sno: 1,
      data: "Site down",
      _id: "siteDown",
      status: "siteDown",
    },
    {
      sno: 2,
      data: "Top associated brand",
      _id: "topAssociatedBrand",
      status: "topAssociatedBrandStatus",
    },
    {
      sno: 3,
      data: "Top associated developer",
      _id: "topAssociatedDevelopers",
      status: "topAssociatedDeveloperStatus",
    },
    {
      sno: 4,
      data: "Featured property",
      _id: "featuredProperty",
      status: "featuredPropertyStatus",
    },
    {
      sno: 5,
      data: "Featured demand",
      _id: "featuredDemand",
      status: "featuredDemandStatus",
    },
    {
      sno: 6,
      data: "Testimonials",
      _id: "testimonial",
      status: "testimonialStatus",
    },
    {
      sno: 7,
      data: "Clientele",
      _id: "clientele",
      status: "clienteleStatus",
    }


  ];

  const getAdvertisementdata = async () => {
    try {
      setLoading(true);
      let url = "/contentManagement/getAllAdvertise?pageNo=0&limit=10";
      const getAdvertiseData = await getData(url, {}, {});
      const { data, status } = getAdvertiseData;

      if (status) {
        setLoading(false);

        let listData = data.list;
        listData.map((item) => {
          if (item.entity_refer === "Brand") {
            item.entityName = item.brandInfo?.name;
          }

          // console.log(data?.advertiseTitle, item.advertiseType)
          if (item.advertiseType === "EXTERNAL") {
            item.entityName = item?.advertiseTitle || "-";
          }
          return item;
        });

        setAdvertisementDetail(listData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContentdata = async () => {
    setLoading(true);
    let url = "/contentManagement/";
    const getcontentDatas = await getData(url, {}, {});
    const { data, status } = getcontentDatas;
    if (status) {
      setLoading(false);
      setContentdatas(data);
      setbannerdata(data?.bannerImages);
      // setbannertoggle(data?.siteDown);

      listData.map((item) => {
        if (data[item.status]) {
          item.statusValue = data[item.status];
        }
        return item;
      });
      setListValue([...listData]);

      localStorage.setItem("contentManagementID", JSON.stringify(data?._id));
    } else {
      setLoading(false);
    }
  };

  const onActiveStatusChange = (id, stats) => {
    setModalOpen(true);
    setContentId(id);
    setStat(stats);
    setBannerToggle(stats);
  };

  const triggerToggleApi = async () => {
    let url = `/contentManagement/changeStatus/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}?type=${contentId}`;
    let dataFormat = {
      status: !stat,
    };
    const newData = await putData(url, {}, dataFormat);
    const { status } = newData;
    if (status) {
      setModalOpen(false);
      getContentdata();
    }
  };

  const clickOk = () => {
    triggerToggleApi();
  };
  const clickCancel = () => {
    setModalOpen(false);
  };

  const handelShowAddBanner = () => {
    setShowBanner(true);
    setAction("Add");
  };

  const handelShowEditBanner = (row) => {
    setEditValue({
      brandId: row.brandId,
      image: row.image,
      brandName: row.brandName,
      type: row?.type,
      _id: row?._id,
      viewUrl: row?.imageViewUrl,
    });

    setShowBanner(true);
    setAction("Edit");
  };

  const handelShowEditAdvertise = (row) => {
    setEditAdvertise(row);
    setAdvertiseModal(true);
    setAction("Edit");
  };

  const handelCloseAddBanner = (value) => {
    setShowBanner(false);
    if (value === "updated") {
      getContentdata();
    }
  };

  const handelCloseAdvertise = (value) => {
    setAdvertiseModal(false);
    if (value === "updated") {
      getAdvertisementdata();
    }
  };

  const handelShowConfirmation = (id, type) => {
    setConfirmation(true);
    if (type === 1) {
      setBannerImageId(id);
    } else {
      setAdvertiseId(id);
    }
  };

  const handelCloseConfirmation = () => {
    setConfirmation(false);
  };

  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <Form.Check
        type="switch"
        id={`custom-switch_${rowIndex}}`}
        checked={row?.statusValue ? row?.statusValue : false}
        onChange={(e) =>
          onActiveStatusChange(
            row._id,
            row?.statusValue ? row?.statusValue : false
          )
        }
        disabled={access?.enable ? false : true}
      />
    );
  }

  function userButtonFormatterBanner(cell, row, rowIndex) {
    return (
      <>
        <Button
          className="table-btn p-0"
          disabled={access?.edit ? false : true}
        >
          <FaPen
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowEditBanner(row)}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access?.delete ? false : true}
        >
          <FaTrash
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowConfirmation(row._id, 1)}
          />
        </Button>
      </>
    );
  }

  function userButtonFormatterAdvertise(cell, row, rowIndex) {
    return (
      <>
        <Button
          className="table-btn p-0"
          disabled={access?.edit ? false : true}
        >
          <FaPen
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowEditAdvertise(row)}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access?.delete ? false : true}
        >
          <FaTrash
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowConfirmation(row._id, 2)}
          />
        </Button>
      </>
    );
  }

  const headerDetail = [
    {
      dataField: "sno",
      text: "Sr.no",
    },
    {
      dataField: "data",
      text: "Landing page contents",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];

  const bannerHeaderDetail = [
    {
      dataField: "sno",
      text: "Sr.no",
    },
    {
      dataField: "brandName",
      text: "Brand Name",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBanner,
    },
  ];


  const advertiseHeaderDetail = [
    {
      dataField: "sno",
      text: "Sr.no",
    },
    {
      dataField: "entityName",
      text: "Advertisement Title",
    },
    {
      dataField: "advertiseType",
      text: "Type",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterAdvertise,
    },
  ];

  const tableProps = {
    data: listValue,
    columns: headerDetail,
  };

  const bannerTableProps = {
    data: bannerData,
    columns: bannerHeaderDetail,
  };

  const advertiseTableProps = {
    data: advertisementDetail,
    columns: advertiseHeaderDetail,
  };

  const deleteBannerImage = async () => {
    let url = `/contentManagement/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}`;
    let dataFormat = {
      bannerImageId,
    };
    const newData = await deleteData(url, {}, dataFormat);
    const { status } = newData;
    if (status) {
      getContentdata();
      handelCloseConfirmation();
    }
  };

  const deleteAdvertise = async () => {
    if (advertiseId) {
      let url = `/contentManagement/deleteAdvertisement/${advertiseId}`;
      const newData = await deleteData(url, {}, {});
      const { status } = newData;
      if (status) {
        getAdvertisementdata();
        handelCloseConfirmation();
      }
    }
  };

  const tabDetails = [
    {
      title: "Content",
      content: <MainTable {...tableProps} />,
      eventKey: "content",
    },
    {
      title: "Testimonial",
      content: <Testmonials access={access} apiData={contentDatas} />,
      eventKey: "testimonial",
    },
    // {
    //   title: "Banner Images",
    //   content: (
    //     <>
    //       <div className="d-flex justify-content-between align-items-center mt-5 mb-4 mobileFlexWrapper">
    //         <div className="brand_text">
    //           <p className="contentManagementText">Banner Management</p>
    //         </div>
    //         <div>
    //           &nbsp;&nbsp;&nbsp;
    //           <Button
    //             className="btn-color-primary"
    //             disabled={
    //               bannerData?.length >= 5 || !access?.add ? true : false
    //             }
    //             onClick={() => handelShowAddBanner()}
    //           >
    //             Add
    //           </Button>
    //           &nbsp;
    //         </div>
    //       </div>
    //       <MainTable {...bannerTableProps} />

    //       {showBanner && (
    //         <AddBanner
    //           label={action}
    //           onClose={handelCloseAddBanner}
    //           show={showBanner}
    //           editValue={editValue}
    //         />
    //       )}
    //     </>
    //   ),
    //   eventKey: "bannerImages",
    // },
    {
      title: "Advertisement",
      content: (
        <>
          <div className="d-flex justify-content-between align-items-center mt-5 mb-4 mobileFlexWrapper">
            <div className="brand_text">
              <p className="advertiseManagementText">Advertise Management</p>
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;
              <Button
                className="btn-color-primary"
                onClick={() => {
                  setAdvertiseModal(true);
                  setAction("Add");
                }}
              >
                Add
              </Button>
              &nbsp;
            </div>
          </div>
          <MainTable {...advertiseTableProps} />

          {advertiseModal && (
            <AddAdvertise
              label={action}
              onClose={handelCloseAdvertise}
              show={advertiseModal}
              editValue={editAdvertise}
            />
          )}
        </>
      ),
      eventKey: "advertiseManage",
    },
    {
      title: "Clientele",
      content: <Clientele access={access} />,
      eventKey: "clientele",
    },
    {
      title: "Statistics",
      content: <Statistics access={access} />,
      eventKey: "statistics",
    },
    {
      title: "Category",
      content: <CategoriesPage />,
      eventKey: "category",
    },
    {
      title: "Splash Screen",
      content: <SplashScreen />,
      eventKey: "splashScreen",
    },
    {
      title: "Cover Image",
      content: <CoverImage />,
      eventKey: "CoverImage",
    },
  ];

  useEffect(() => {
    getContentdata();
    getAdvertisementdata();
  }, []);

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      {loginPermission?.includes(menuId?.contentManagement) ? (
        <div>
          <NavBar />
          {/* <DashboardLayout> */}
          {modalOpen && (
            <FormModal
              open={modalOpen}
              close={setModalOpen}
              onClickOk={clickOk}
              onClickCancel={clickCancel}
              types={bannerToggle}
            />
          )}
          <div
            // style={{ backgroundColor: "#FFFFFF", padding: "40px" }}
            className="content_fullcards"
          >
            <Tab
              tabDetails={tabDetails}
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
              }}
            />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}

      {/* {confirmation && (
        <AdminWarningPopup
          onClose={handelCloseConfirmation}
          show={true}
          message={"Are you sure you want delete this banner?"}
          warningYesClick={() => deleteBannerImage()}
        />
      )} */}

      {confirmation && (
        <AdminWarningPopup
          onClose={handelCloseConfirmation}
          show={true}
          message={"Do you want to delete this advertisement?"}
          warningYesClick={() => deleteAdvertise()}
        />
      )}


    </>
  );
};

export default ContentManagement;
