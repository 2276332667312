import React, { useState, useEffect } from "react";
import { DashboardCard } from "../../components/Cards/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  AccessDenied,
  getCurrentUser,
  getData,
  refreshData,
} from "../../api/index";

import Dashboard from "../../assets/images/dbs.png";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

import { socket } from "../chat/socket";
import { useSelector } from "react-redux";
import { DashboardCardRm } from "../../components/Cards/DashboardRM";
import { Col, Row } from "react-bootstrap";
import BuyerDashboard from "../../assets/images/BuyerDashboard.png";
import DeveloperDashboard from "../../assets/images/DeveloeprDashboard.png";
import AgentDashboard from "../../assets/images/AgentDashbaord.png";
import RefreshButton from "../../components/RefreshButton";
import SkeletonLoader from "../../components/SkeletonLoader";
import { PHYSICALTEAM, PHYSICAL_TEAM } from "../../custom/constants";
const RMDashBoard = ({}) => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);
  const [isLoadingDeveloper, setIsLoadingDeveloper] = useState(false);
  const [isLoadingAgent, setIsLoadingAgent] = useState(false);
  const [isLoadingEAgent, setIsLoadingEAgent] = useState(false);
  const [isLoadingPhysicalTeam, setIsLoadingPhysicalTeam] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [rmCardData, setRmCardData] = useState([]);
  const [rmBrandCardData, setRmBrandCardData] = useState([]);
  const [rmDeveloeprCardData, setRmDeveloeprCardData] = useState([]);
  const [rmAgentCardData, setRmAgentCardData] = useState([]);
  const [rmEAgentCardData, setRmEAgentCardData] = useState([]);
  const [rmPhysicalTeamCardData, setRmPhysicalTeamCardData] = useState([]);
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();
  const loginData = useSelector((state) => state.login.loginData);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const [refLoading, setRefLoading] = useState(false);
  const rmDashBoardName = "dashboard";

  let dashboardPermission = loginPermission?.includes(menuId.dashboard);
  let buyerdashboardPermission = loginPermission?.includes(
    menuId.enterpriseBuyerDashboard
  );
  let sellerdashboardPermission = loginPermission?.includes(
    menuId.enterpriseSellerDashboard
  );
  let agentdashboardPermission = loginPermission?.includes(
    menuId.agentDashboard
  );
  function isObject(value) {
    return typeof value === "object" && value !== null;
  }
  const Staticdetails = [
    {
      key: "totalEnterpriseBuyer",
      label: "Enterprise Buyer",
      permissionKey: "enterpriseBuyerDashboard",
      viewUrl: BuyerDashboard,
      datas: [
        {
          name: "demand",
          label: "Demands",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/enterpriseBuyer/demands`,
        },
        {
          name: "response",
          label: "Properties Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/enterpriseBuyer/propertiesReceivedAll`,
        },
        {
          name: "relevency",
          label: "Relevant Properties",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/enterpriseBuyer/relevantPropertiesAll`,
        },
        {
          name: "inbox",
          label: "Unread Messages",
          readKey: "inboxIsRead",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/enterpriseBuyer/unreadInboxMessages`,
        },
      ],
    },
    {
      label: "Enterprise Seller",
      key: "totalEnterpriseSeller",
      permissionKey: "enterpriseSellerDashboard",
      viewUrl: DeveloperDashboard,
      datas: [
        {
          name: "property",
          label: "Properties",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/enterpriseSeller/properties`,
        },
        {
          name: "response",
          label: "Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/enterpriseSeller/interestReceivedAll`,
        },
        {
          name: "relevency",
          label: "Matching Demands",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/enterpriseSeller/relevantDemandsAll`,
        },
        {
          name: "inbox",
          label: "Unread Messages",
          readKey: "inboxIsRead",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/enterpriseSeller/unreadInboxMessages`,
        },
        {
          name: "projects",
          label: "Projects",
          readKey: "",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/enterpriseSeller/Projects`,
        },
        {
          name: "projectLeads",
          label: "Projects Lead",
          readKey: "",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/enterpriseSeller/projectLeads`,
        },
      ],
    },
    {
      label: "Agent",
      key: "totalAgent",
      permissionKey: "agentDashboard",
      viewUrl: AgentDashboard,
      datas: [
        {
          name: "property",
          label: "Properties",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/agent/properties`,
        },
        {
          name: "propertyResponse",
          label: "Property Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/agent/interestReceivedAll`,
        },
        {
          name: "propertyRelevency",
          label: "Matching Demands",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/agent/relevantDemandsAll`,
        },
        {
          name: "inbox",
          label: "Unread Messages",
          readKey: "inboxIsRead",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/agent/unreadInboxMessages`,
        },
        {
          name: "demand",
          label: "Demands",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/agent/demands`,
        },
        {
          name: "demandResponse",
          label: "Demand Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/agent/propertiesReceivedAll`,
        },
        {
          name: "demandRelevency",
          label: "Relevant Properties",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/agent/relevantPropertiesAll`,
        },
      ],
    },
    {
      label: "Enterprise Agent",
      key: "totalEAgent",
      permissionKey: "agentDashboard",
      viewUrl: AgentDashboard,
      datas: [
        {
          name: "eagentProperty",
          label: "Properties",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/enterpriseAgent/properties`,
        },
        {
          name: "eagentPropertyResponse",
          label: "Property Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/enterpriseAgent/interestReceivedAll`,
        },
        {
          name: "eagentPropertyRelevency",
          label: "Matching Demands",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/enterpriseAgent/relevantDemandsAll`,
        },
        {
          name: "eagentInbox",
          label: "Unread Messages",
          readKey: "inboxIsRead",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/enterpriseAgent/unreadInboxMessages`,
        },
        {
          name: "eagentDemand",
          label: "Demands",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/enterpriseAgent/demands`,
        },
        {
          name: "eagentDemandResponse",
          label: "Demand Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/enterpriseAgent/propertiesReceivedAll`,
        },
        {
          name: "eagentDemandRelevency",
          label: "Relevant Properties",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/enterpriseAgent/relevantPropertiesAll`,
        },
      ],
    },
    {
      label: PHYSICAL_TEAM,
      key: "totalEAgent",
      permissionKey: "agentDashboard",
      viewUrl: AgentDashboard,
      datas: [
        {
          name: "eagentProperty",
          label: "Properties",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/properties`,
        },
        {
          name: "eagentPropertyResponse",
          label: "Property Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/interestReceivedAll`,
        },
        {
          name: "eagentPropertyRelevency",
          label: "Matching Demands",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/relevantDemandsAll`,
        },
        {
          name: "eagentInbox",
          label: "Unread Messages",
          readKey: "inboxIsRead",
          interest: "inbox",
          value: "",
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/unreadInboxMessages`,
        },
        {
          name: "eagentDemand",
          label: "Demands",
          readKey: "posted",
          interest: "",
          value: "",
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/demands`,
        },
        {
          name: "eagentDemandResponse",
          label: "Demand Interest Received",
          readKey: "responsesIsRead",
          interest: "response",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/propertiesReceivedAll`,
        },
        {
          name: "eagentDemandRelevency",
          label: "Relevant Properties",
          readKey: "releventIsRead",
          interest: "relevent",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/relevantPropertiesAll`,
        },
        {
          name: "projectLeads",
          label: "Projects Lead",
          readKey: "responsesIsRead",
          interest: "inboxs",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/projectLeads`,
        },
        {
          name: "projects",
          label: "Project Assigned",
          readKey: "responsesIsRead",
          interest: "inboxs",
          value: {},
          url: `/${rmDashBoardName}/${PHYSICALTEAM}/Projects`,
        },
      ],
    },
  ];

  const getDashboard = async () => {
    let url = "/dashboard/dashboardimages";
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setDashboardData(data);
    } else {
      setDashboardData();
    }
  };

  const redirectURLS = [
    {
      label: "Enterprise Buyer",
      url: `/${rmDashBoardName}/enterpriseBuyer/demands`,
      name: "demand",
    },
    {
      label: "Enterprise Buyer",
      url: `/${rmDashBoardName}/enterpriseBuyer/propertiesRecievedAll`,
      name: "response",
    },
    {
      label: "Enterprise Buyer",
      url: `/${rmDashBoardName}/enterpriseBuyer/unreadInboxMessages`,
      name: "inbox",
    },
    {
      label: "Enterprise Buyer",
      url: `/${rmDashBoardName}/enterpriseBuyer/relevantPropertiesAll`,
      name: "relevency",
    },
    {
      label: "Enterprise Seller",
      url: `/${rmDashBoardName}/enterpriseSeller/properties`,
      name: "property",
    },
    {
      label: "Enterprise Seller",
      url: `/${rmDashBoardName}/enterpriseSeller/unreadInboxMessages`,
      name: "inbox",
    },
    {
      label: "Enterprise Seller",
      url: `/${rmDashBoardName}/enterpriseSeller/interestRecievedAll`,
      name: "response",
    },
    {
      label: "Enterprise Seller",
      url: `/${rmDashBoardName}/enterpriseSeller/relevantDemandsAll`,
      name: "relevency",
    },
    {
      label: "Agent",
      url: `/${rmDashBoardName}/agent/properties`,
      name: "property",
    },
    {
      label: "Agent",
      url: `/${rmDashBoardName}/agent/unreadInboxMessages`,
      name: "inbox",
    },

    {
      label: "Agent",
      url: `/${rmDashBoardName}/agent/interestRecievedAll`,
      name: "propertyResponse",
    },
    {
      label: "Agent",
      url: `/${rmDashBoardName}/agent/relevantDemandsAll`,
      name: "propertyRelevency",
    },
  ];

  // function addURLToMainData(mainData, redirectURLS) {
  //   mainData.forEach((data) => {
  //     data.datas.forEach((item) => {
  //       const matchingURL = redirectURLS.find(
  //         (urlData) =>
  //           urlData.label === data.label && urlData.name === item.name
  //       );
  //       if (matchingURL) {
  //         item.url = matchingURL.url;
  //       }
  //     });
  //   });
  // }

  const getDemandUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoadingBrand(true);
    }
    const demandDashboardUrl = "/demand/getDashboardDemand";

    const demandDashboardData = await getData(demandDashboardUrl);

    if (demandDashboardData?.statusCode !== 200) {
      setCardData([]);
      setIsLoadingBrand(false);
      return;
    }

    const staticDashboardResponse = [demandDashboardData?.data];

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);
    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmBrandCardData(updatedStaticCardData);
    setIsLoadingBrand(false);
  };
  const getDeveloperUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoadingDeveloper(true);
    }
    const developerDashboardUrl = "/demand/getDashboardProperty";

    const developerDashboardData = await getData(developerDashboardUrl);

    if (developerDashboardData?.statusCode !== 200) {
      setCardData([]);
      setIsLoadingDeveloper(false);
      return;
    }

    const staticDashboardResponse = [developerDashboardData?.data];

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);

    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmDeveloeprCardData(updatedStaticCardData);
    setIsLoadingDeveloper(false);
  };

  const getAgentUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoadingAgent(true);
    }
    const agentDashboardUrl = "/demand/getDashboardAgent";

    const agentDashboardData = await getData(agentDashboardUrl);

    if (agentDashboardData?.statusCode !== 200) {
      setCardData([]);
      setIsLoadingAgent(false);
      return;
    }

    const staticDashboardResponse = [agentDashboardData?.data];

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);

    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmAgentCardData(updatedStaticCardData);
    setIsLoadingAgent(false);
  };
  const getEAgentUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoadingEAgent(true);
    }
    const agentDashboardUrl = "/demand/getDashboardEAgent";

    const agentDashboardData = await getData(agentDashboardUrl);

    if (agentDashboardData?.statusCode !== 200) {
      setCardData([]);
      setIsLoadingEAgent(false);
      return;
    }

    const staticDashboardResponse = [agentDashboardData?.data];

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);

    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmEAgentCardData(updatedStaticCardData);
    setIsLoadingEAgent(false);
  };
  const getPhysicalTeamUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoadingPhysicalTeam(true);
    }
    const physicalTeamDashboardUrl = "/demand/getDashboardPhysicalTeam";

    const agentDashboardData = await getData(physicalTeamDashboardUrl);

    if (agentDashboardData?.statusCode !== 200) {
      setCardData([]);
      setIsLoadingPhysicalTeam(false);
      return;
    }

    const staticDashboardResponse = [agentDashboardData?.data];

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);
    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmPhysicalTeamCardData(updatedStaticCardData);
    setIsLoadingPhysicalTeam(false);
  };
  const getUserData = async (loadStatus) => {
    if (loadStatus) {
      setIsLoading(true);
    }

    let staticURLS = [
      "/demand/getDashboardAgent",
      "/demand/getDashboardEAgent",
      "/demand/getDashboardDemand",
      "/demand/getDashboardProperty",
    ];

    const demandDashboardUrl = "/demand/getDashboardDemand";
    const developerDashboardUrl = "/demand/getDashboardProperty";
    const agentDashboardUrl = "/demand/getDashboardProperty";

    const staticRequests = await Promise.all(
      staticURLS.map((url) => getData(url))
    );

    const demandDashboardData = await getData(demandDashboardUrl);
    const developerDashboardData = await getData(developerDashboardUrl);

    const successfulStaticResponses = staticRequests.filter(
      (response) => response.statusCode === 200
    );

    if (successfulStaticResponses.length !== staticURLS.length) {
      setCardData([]);
      setIsLoading(false);
      return;
    }

    const staticDashboardResponse = staticRequests?.map((item) => {
      const val = item?.data;
      return val;
    });

    const updatedStaticCardData = Staticdetails?.reduce((obj, item) => {
      const match = staticDashboardResponse.find((k) => item.key in k);
      if (!match) {
        return obj;
      }
      const updatedItem = {
        ...item,
        userCount: match[item.key],
        datas: item.datas.map((val) => {
          const newVal = {
            ...val,
            isRead:
              val?.readKey === "posted" || val?.readKey === "inboxIsRead"
                ? true
                : false,
            value: match[val?.name],
          };
          return newVal;
        }),
      };
      obj.push(updatedItem);
      return obj;
    }, []);

    // addURLToMainData(updatedStaticCardData, redirectURLS);
    setRmCardData(updatedStaticCardData);
    setIsLoading(false);
  };

  const viewPage = (url, isNew, label) => {
    if (url) {
      let sectionType;
      if (label === "Enterprise Buyer") {
        sectionType = "Brand";
      } else if (label === "Enterprise Seller") {
        sectionType = "Developer";
      } else {
        sectionType = label;
      }
      if (isNew) {
        let newUrl = url.replace("All", "New");
        navigate(newUrl, { state: { sectionType } });
      } else {
        navigate(url, { state: { sectionType } });
      }
    }
  };
  useEffect(() => {
    getDashboard();
    // getUserData(true);
    getDemandUserData(true);
    getDeveloperUserData(true);
    getAgentUserData(true);
    getEAgentUserData(true);
    getPhysicalTeamUserData(true);
  }, []);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("keydemand_user"));
    const eventHandler = () => setConnected(true);
    const eventId = user?._id;
    socket.on(eventId, eventHandler);
    socket.on(eventId, (newMessage) => {
      if (
        (newMessage?.responseCount && String(newMessage?.responseCount)) ||
        (newMessage?.propertyCount && String(newMessage?.propertyCount)) ||
        (newMessage?.newProperty && String(newMessage?.newProperty)) ||
        (newMessage?.newDemand && String(newMessage?.newDemand))
      ) {
        // getUserData(false);
      }
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off(eventId, eventHandler);
    };
  }, []);

  const refreshDashbaordCounts = async () => {
    const url1 = `/dashboard/setRelevancyData?type=superAdminDemandSummary`;
    const url2 = `/dashboard/setRelevancyData?type=superAdminPropertySummary`;
    const url3 = `/dashboard/setRelevancyData?type=superAdminAgentSummary`;
    const url4 = `/dashboard/setRelevancyData?type=superAdminEAgentSummary`;

    setRefLoading(true);

    try {
      // Use Promise.all to send three requests concurrently
      const [response1, response2, response3] = await Promise.all([
        refreshData(url1),
        refreshData(url2),
        refreshData(url3),
        refreshData(url4),
      ]);

      setRefLoading(false);

      // Check if all requests were successful
      if (response1?.status && response2?.status && response3?.status) {
        Swal.fire({
          text: "Please wait! New data will reflect after 5 Min",
          icon: "success",
          timer: 5000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        getDemandUserData(true);
        getDeveloperUserData(true);
        getAgentUserData(true);
        getEAgentUserData(true);
      }
    } catch (error) {
      console.error("Error while refreshing data:", error);
      setRefLoading(false);
      // Handle error as needed
    }
  };
  return (
    <>
      {/* {isLoading && <Loader className={"fixed"} />} */}
      {!isLoading && (
        <>
          {dashboardPermission &&
          (buyerdashboardPermission ||
            sellerdashboardPermission ||
            agentdashboardPermission) ? (
            <div className="dashboard-container">
              <div className="db-bg-img">
                <div className="db-bg-content">
                  <div className="db-welcomeMsg">
                    {dashboardData?.welcomeMessage}
                  </div>
                  <div className="db-subtext">{dashboardData?.content}</div>
                </div>
                <img
                  src={
                    dashboardData?.homeViewUrl
                      ? dashboardData?.homeViewUrl
                      : Dashboard
                  }
                  alt="home"
                />
              </div>

              <div className="dashboard_Refresh">
                <RefreshButton
                  loading={refLoading}
                  onClick={refreshDashbaordCounts}
                />
              </div>

              <div
                className=""
                style={{ display: "grid", grid: "3", gap: "8px" }}
              >
                <Row className="gap-3 mt-1">
                  <>
                    {/* {isLoadingBrand && <Col xs={12} xl={12}><SkeletonLoader/></Col>} */}

                    {loginPermission?.includes(
                      menuId[Staticdetails[0]?.permissionKey]
                    ) && (
                      <React.Fragment>
                        <Col xs={12} xl={12}>
                          <DashboardCardRm
                            data={rmBrandCardData[0]}
                            viewPage={viewPage}
                            loading={isLoadingBrand}
                            loadingSkeleton={Staticdetails[0]}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    {loginPermission?.includes(
                      menuId[Staticdetails[1]?.permissionKey]
                    ) && (
                      <React.Fragment>
                        <Col xs={12} xl={12}>
                          <DashboardCardRm
                            data={rmDeveloeprCardData[0]}
                            viewPage={viewPage}
                            loading={isLoadingDeveloper}
                            loadingSkeleton={Staticdetails[1]}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    {loginPermission?.includes(
                      menuId[Staticdetails[3]?.permissionKey]
                    ) && (
                      <React.Fragment>
                        <Col xs={12} xl={12}>
                          <DashboardCardRm
                            data={rmEAgentCardData[0]}
                            viewPage={viewPage}
                            loading={isLoadingEAgent}
                            loadingSkeleton={Staticdetails[3]}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    {loginPermission?.includes(
                      menuId[Staticdetails[3]?.permissionKey]
                    ) && (
                      <React.Fragment>
                        <Col xs={12} xl={12}>
                          <DashboardCardRm
                            data={rmPhysicalTeamCardData[1]}
                            viewPage={viewPage}
                            loading={isLoadingPhysicalTeam}
                            loadingSkeleton={Staticdetails[4]}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    {loginPermission?.includes(
                      menuId[Staticdetails[2]?.permissionKey]
                    ) && (
                      <React.Fragment>
                        <Col xs={12} xl={12}>
                          <DashboardCardRm
                            data={rmAgentCardData[0]}
                            viewPage={viewPage}
                            loading={isLoadingAgent}
                            loadingSkeleton={Staticdetails[2]}
                          />
                        </Col>
                      </React.Fragment>
                    )}
                  </>
                </Row>

                {/* );
                  })} */}
              </div>
            </div>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </>
  );
};
export default RMDashBoard;
