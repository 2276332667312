import { useState, useEffect } from "react";
import FormModal from "../../components/Modal";
import Select from "../../components/Form/Select";
import SingleImageDropzone from "../../components/dragAndDrop/singleFileDrop";
import Button from "../../components/Form/Button";
import { postUploadData, getData, putData, postData } from "../../api/index";
import Loader from "../../components/Loader";
import Input from "../../components/Form/Input";
import MultiSelect from "../../components/select/dropDown";

export function AddAdvertise({ onClose, show, label, editValue }) {
  const [errorMessage, setErrorMessage] = useState({});
  const [formValues, setFormValues] = useState({
    advertiseType : 'INTERNAL',
    entity_id: "",
    external_link: "",
    desktop_img: "",
    mobile_img: "",
    userType:"",
    user_base : []
  });
  const [deskimageData, setDeskImageData] = useState({});
  const [mobileimageData, setMobileImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSubmitloading, setformSubmitLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [userBaseSelection, setUserBaseSelection] = useState([]);

  const handelOnSelect = (value, name) => {
    delete errorMessage[name];
    let data = { ...formValues };
    data[name] = value
    setFormValues(data);
  };

  function isValidURL(string) {
    const regex = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[?[a-f\d:]+?\]?)?(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\-/a-z\d_]*)?$/i;
    return regex.test(string);
  }

  const checkErrorMessage = (data) => {
    let error = {};
    let requriedMessage = "This Field Is Required";
    // type: "Developer",

    const {advertiseType, advertiseTitle, external_link, entity_id, userType} = data
    if (!advertiseType) {
      error.advertiseType = requriedMessage;
    }
    
    if(advertiseType){ 
        if(advertiseType==='INTERNAL'){
            if(!userType){
                error.userType = requriedMessage;  
            }

            if(!entity_id){
                error.entity_id = requriedMessage;  
            }
            

        }else{
            // if(!advertiseTitle){
            //     error.advertiseTitle = requriedMessage;         
            // }

            if(!external_link){
                error.external_link = requriedMessage;
            }
            
            if(external_link){
                let isValid = isValidURL(external_link);
                if(!isValid){
                    error.external_link = 'Please enter valid link';
                }
            }
        }
    }

    if (!data.desktop_img) {
      error.desktop_img = requriedMessage;
    }

    if (!data.mobile_img) {
        error.mobile_img = requriedMessage;
    }

    return error;
  };


  const handleSubmitForm = async () => {
    setformSubmitLoading(true);
    let passData = {...formValues};
    if(passData.advertiseType==='EXTERNAL'){
        delete passData.entity_id
        delete passData.userType
        delete passData.entity_refer

    }else{
        delete passData.advertiseTitle
        delete passData.external_link

        if(passData.userType!=='Project'){
            passData.entity_refer='Brand'
        }
    }

    if (label === "Edit") {
        let idDoc = editValue?._id;
        let url = `/contentManagement/updateAdvertise/${idDoc}`;
        const zoneData = await putData(url, {}, passData);
        const {  status } = zoneData;
        if (status) {
            setformSubmitLoading(false);
            onClose("updated");
          } else {
            setformSubmitLoading(false);
          }

    }else{
        let url = `/contentManagement/addAdvertise`;
        const zoneData = await postData(url, {}, passData);
        const {  status } = zoneData;
        if (status) {
          setformSubmitLoading(false);
          onClose("updated");
        } else {
          setformSubmitLoading(false);
        }
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const checkError = checkErrorMessage(formValues);
    setErrorMessage(checkError);

    let NoError = Object.values(checkError).every((v) => v.length === 0);
    if (NoError) {
      handleSubmitForm();
    }
  };

  useEffect(() => {
    if (label === "Edit") {
        if(editValue.advertiseType ==='INTERNAL'){
            let d = getLabelBaseUser(editValue?.userType);   
        }

        setDeskImageData({viewUrl : editValue?.desktop_img?.viewUrl});
        setMobileImageData({viewUrl : editValue?.mobile_img?.viewUrl});

        setFormValues((pre) => {
            return {
             ...pre,
             ...{
                advertiseType: editValue.advertiseType,
                entity_id: editValue.entity_id,
                external_link: editValue.external_link,
                userType: editValue?.userType,
                desktop_img: editValue?.desktop_img?.url,
                mobile_img: editValue?.mobile_img?.url,
                advertiseTitle : editValue?.advertiseTitle || [],
                user_base : editValue?.user_base || [],
             },
            };
        });


        const vals = editValue?.user_base.map(name => ({label : name, value : name}));
        setUserBaseSelection(vals)

    }
  }, []);
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [formValues]);

const advertiseTypes = [{
    _id : 'INTERNAL',
    name : 'Internal'},
    {
        _id : 'EXTERNAL',
        name : 'External'
    }]

 const userType = [
    {_id : 'BRAND', name : 'Brand'},
    {_id : 'DEVELOPER', name : 'Developer'},
    {_id : 'OPERATOR', name : 'Operator'},
    {_id : 'OCCUPIER', name : 'Occupier'},
    //{_id : 'Project', name : 'Project'}
 ]

 async function getLabelBaseUser(label){
    try{
        setLoading(true);
        let url =`/brand/getLabelBaseUser?label=${label}`;
        const getbrand = await getData(url, {});
        const { data, status } = getbrand;
        if (status) {
            setLoading(false);
            setEntityList(data);
            return data || [];
        }
        setLoading(false);
        setEntityList([]);
        return []
    }catch(err){
        console.log(':: getLabelBaseUser :: ',err)
        setEntityList([]);
        return[];
    }
 }
 async function getRespectiveList(event){   
    let label = event.target.value;
    const users = await getLabelBaseUser(label);
    handelOnSelect(label, "userType");
 }

 const handleImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uploadfiles", file[0]);
    let url = `/contentMangementRevamp/file-upload/?module=advertise&type=image`;
    const imageDataUpload = await postUploadData(url, {}, formData);
    const { data, status } = imageDataUpload;

    if (status && data.length > 0) {
        let d = data[0]
        setLoading(false);
        setDeskImageData(d);
        setFormValues((pre) => ({ ...pre, desktop_img: d?.url }));
        setErrorMessage((pre) => ({ ...pre, desktop_img: "" }));
    } else {
      setLoading(false);
    }
  };

  const handleMobileImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uploadfiles", file[0]);
    let url = `/contentMangementRevamp/file-upload/?module=advertise&type=image`;
    const imageDataUpload = await postUploadData(url, {}, formData);
    const { data, status } = imageDataUpload;

    if (status && data.length > 0) {
        let d = data[0]
        setLoading(false);
        setMobileImageData(d);
        setFormValues((pre) => ({ ...pre, mobile_img: d?.url }));
        setErrorMessage((pre) => ({ ...pre, mobile_img: "" }));
    } else {
      setLoading(false);
    }
  };

  function getValues(d){
    let result = d.map(a => a.value);
    return result
  }

  function userOnChange(e){
    setUserBaseSelection(e)
    let k = getValues(e)    
    handelOnSelect(k, "user_base");
  }

  return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={`${label} Advertisement`}
        size={"md"}
        loading={initLoading}
        loader="fixed"
      >
        <div className="mt-1">
          <div className="select-head">
            <form noValidate onSubmit={handleSubmit}>

            <Select
                errorMessage={
                  errorMessage?.advertiseType && errorMessage?.advertiseType
                }
                label={"Advertisement Type*"}
                options={advertiseTypes}
                name={"advertiseType"}
                isInvalid={errorMessage?.advertiseType && true}
                value={formValues?.advertiseType && formValues?.advertiseType}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "advertiseType");
                }}
              />

            {formValues?.advertiseType ==='EXTERNAL' &&
              <Input
                  errorMessage={errorMessage?.advertiseTitle && errorMessage?.advertiseTitle}
                  label={"Name "}
                  type={"text"}
                  name={"advertiseTitle"}
                  placeholder={"Enter advertisement name"}
                  onChange={(e) => {
                    handelOnSelect(e.target.value, "advertiseTitle");
                  }}
                  value={formValues?.advertiseTitle}
                  isInvalid={errorMessage?.advertiseTitle && true}
                />}

            {formValues?.advertiseType ==='EXTERNAL' &&
               <Input
                  errorMessage={errorMessage?.external_link && errorMessage?.external_link}
                  label={"Link *"}
                  type={"text"}
                  name={"external_link"}
                  placeholder={"Enter Admin Name"}
                  onChange={(e) => {
                    handelOnSelect(e.target.value, "external_link");
                  }}
                  value={formValues?.external_link}
                  isInvalid={errorMessage?.external_link && true}
                />}

            {formValues?.advertiseType ==='INTERNAL' &&
              <Select
                errorMessage={
                  errorMessage?.userType && errorMessage?.userType
                }
                label={"User Type/Project*"}
                options={userType}
                name={"userType"}
                isInvalid={errorMessage?.userType && true}
                value={formValues?.userType && formValues?.userType}
                onChange={(e) => {
                    handelOnSelect('', "entity_id");
                    getRespectiveList(e);
                }}
              />}

            {formValues?.advertiseType ==='INTERNAL' &&
              <Select
                errorMessage={
                  errorMessage?.entity_id && errorMessage?.entity_id
                }
                label={"Name*"}
                options={entityList}
                name={"entity_id"}
                isInvalid={errorMessage?.entity_id && true}
                value={formValues?.entity_id && formValues?.entity_id}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "entity_id");
                }}
              />}

            <div>
                <MultiSelect
                    onChange={(val) => userOnChange(val)}
                    isMulti={true}
                    name={"user_base"}
                    options={[
                      { label: "Brand", value: "Brand" },
                      { label: "Developer", value: "Developer" },
                      { label: "Agent", value: "Agent" },
                      { label: "Flex Occupier", value: "Flex Occupier" },
                      { label: "Flex Operator", value: "Flex Operator" },

                    ]}
                    value={userBaseSelection}
                    lable={"User Base *"}
                    placeholder={"Select User Base"}
                    errormessage={errorMessage?.state}
                  />

              <div className="w-100">
                <label
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#000000",
                    marginBottom: "12px",
                  }}
                >
                  Upload Desktop Advertisement Image*
                </label>

                <SingleImageDropzone
                sizeAd
                error={errorMessage.desktop_img || ''}
                loading={loading}
                onHandelImage={handleImage}
                data={deskimageData}
              />
              </div>

              <div className="w-100">
                <label
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#000000",
                    marginBottom: "12px",
                  }}
                >
                  Upload Mobile Advertisement Image*
                </label>

                <SingleImageDropzone
                   sizeAd
                error={errorMessage.mobile_img || ''}
                loading={loading}
                onHandelImage={handleMobileImage}
                data={mobileimageData}
                resolutionText={"2560 X 1440"}
              />
              </div>

            </div>
             
              <div className="error-msg">{errorMessage.image}</div>

              <div className="d-flex justify-content-end  gap-3 mt-4 ">
                <Button type={"button"} className={"pre-btn"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  className="next-btn"
                  loading={formSubmitloading}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>

        {loading && <Loader className={"fixed"} />}
      </FormModal>
  );
}

export const AdvAdminWarningPopup = ({
  onClose,
  show,
  message,
  warningYesClick,
}) => {
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={"Confirmation"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <p>{message}</p>
      <div className="btn-container">
        <Button className={"btn-outline-color-primary"} onClick={onClose}>
          No
        </Button>
        <Button onClick={warningYesClick} className="btn-color-primary">
          Yes
        </Button>
      </div>
    </FormModal>
  );
};
