import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//library
import { useLocation, useNavigate } from "react-router-dom";

//components
import Button from "../../components/Form/Button";
import Search from "../../components/Search/search";
import Tab from "../../components/Tab/index";

//fetch
import { AccessDenied, getData } from "../../api/index";

//table
import { useContext, useReducer } from "react";
import { AppContext } from "../../App";
import NavBar from "../../components/Navbar";
import MultiSelect from "../../components/select/dropDown";
import { nameToLabel } from "../../utilities/commonFunction";
import {
  getContextFilterData,
  getContextSearchData,
  getTableStoreValue,
  setTableFilterData,
} from "../../utilities/pageScroll";
import {
  updateBrandId,
  updateComapanyId,
  updateCompanyFormStatus,
  updateParentPageData,
} from "../Login/reducer";
import AgentTable from "./CustomerTable/agentTable";
import BuyerTable from "./CustomerTable/enterpriseBuyerTable";
import SellerTable from "./CustomerTable/enterpriseSellerTable";
import "./style.scss";
const Customer = () => {
  const location = useLocation();
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const [key, setKey] = useState(
    location?.state?.customerActiveKey
      ? location?.state?.customerActiveKey
      : localStorage.getItem("customerTabKey")
      ? localStorage.getItem("customerTabKey")
      : loginPermission.includes(menuId.customerBuyer)
      ? "customerBuyer"
      : loginPermission.includes(menuId.customerSeller)
      ? "customerSeller"
      : loginPermission.includes(menuId.customerAgents)
      ? "customerAgent"
      : "customerBuyer"
  );
  const dispatch = useDispatch();
  const { tableStoreContext, setTableStoreContext, pageScroll } =
    useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    key,
    tableStoreContext
  );
  let scrollStoreArray = [...pageScroll];
  let contextFilterData = getContextFilterData(key, scrollStoreArray);
  let contextSearchData = getContextSearchData(key, scrollStoreArray);
  const storetableName = currentTableValueFromConText?.tableName;
  const storePageNo = currentTableValueFromConText?.pageNo;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(
    storetableName === key ? (storePageNo ? storePageNo : 1) : 1
  );
  const [search, setSearch] = useState(
    contextSearchData ? contextSearchData : ""
  );

  const [cityFilterValues, setCityFilterValues] = useState([]);
  let initialState = {
    city: {
      label: "City",
      value: "City",
    },
    active: "",
  };
  let filterinitialState = contextFilterData || initialState;

  const reducer = (state, action) => {
    const stateKeys = {
      CITY: "city",
      ACTIVE: "active",
    };
    const stateKey = stateKeys[action.type];
    if (!stateKey) {
      return state;
    }

    return {
      ...state,
      [stateKey]: action.value,
    };
  };
  const [filter, filterReducerDispatch] = useReducer(
    reducer,
    filterinitialState
  );

  const access = {
    viewBuyerCustomers: loginPermission?.includes(menuId?.viewBuyerCustomers)
      ? true
      : false,
    addBuyerCustomers: loginPermission?.includes(menuId?.addBuyerCustomers)
      ? true
      : false,
    editBuyerCustomers: loginPermission?.includes(menuId?.editBuyerCustomers)
      ? true
      : false,
    buyerEnableDisable: loginPermission?.includes(menuId?.buyerEnableDisable)
      ? true
      : false,
    viewSellerCustomers: loginPermission?.includes(menuId?.viewSellerCustomers)
      ? true
      : false,
    addSellerCustomers: loginPermission?.includes(menuId?.addSellerCustomers)
      ? true
      : false,
    editSellerCustomers: loginPermission?.includes(menuId?.editSellerCustomers)
      ? true
      : false,
    sellerEnableDisable: loginPermission?.includes(menuId?.sellerEnableDisable)
      ? true
      : false,
    viewAgentCustomers: loginPermission?.includes(menuId?.viewAgentCustomers)
      ? true
      : false,
    addAgentCustomers: loginPermission?.includes(menuId?.addAgentCustomers)
      ? true
      : false,
    editAgentCustomers: loginPermission?.includes(menuId?.editAgentCustomers)
      ? true
      : false,
    deleteAgentCustomers: loginPermission?.includes(menuId?.deleteAgentCustomers)
      ? true
      : false,
    agentEnableDisable: loginPermission?.includes(menuId?.agentEnableDisable)
      ? true
      : false,
  };

  useEffect(() => {
    if (!localStorage.getItem("customerTabKey")) {
      localStorage.setItem("customerTabKey", key);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const getCityList = () => {
    let url = "/cityZones/getLocalities?page=1&limit=0";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        let city = [];
        response?.data?.localities?.map((item) => {
          city.push({
            name: item?.name,
            _id: item?.cityId,
          });
          return item;
        });
        setCityFilterValues(nameToLabel(city));
      }
    });
  };

  const onchangeActiveFilterKey = (e) => {
    let val = e.target.value === "Active/Inactive" ? "" : e.target.value;
    filterReducerDispatch({ type: "ACTIVE", value: val });
    setOffset(1);
  };

  const onchangeCityFilterKey = (val) => {
    // let cityId;
    // cityFilterValues.find((el) =>
    //   el.name === e.target.value ? (cityId = el._id) : null
    // );
    filterReducerDispatch({ type: "CITY", value: val });
    // setCityFilterKeyValue({type:"CITY",value=val});
    setOffset(1);
  };

  const tabDetails = [
    loginPermission?.includes(menuId.customerBuyer) && {
      title: "Enterprise Buyers",
      content: (
        <>
          {key === "customerBuyer" ? (
            <BuyerTable
              access={access}
              filter={filter}
              offSet={offset}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerBuyer",
    },
    loginPermission?.includes(menuId.customerSeller) && {
      title: "Enterprise Sellers",
      content: (
        <>
          {key === "customerSeller" ? (
            <SellerTable
              filter={filter}
              offSet={offset}
              access={access}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerSeller",
    },
    loginPermission?.includes(menuId.customerAgents) && {
      title: "Agents",
      content: (
        <>
          {key === "customerAgent" ? (
            <AgentTable
              access={access}
              filter={filter}
              offSet={offset}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerAgent",
    },
  ];

  const onClick = (e, value) => {
    let url = "/role/roletype?type=user";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        if (value === "add") {
          localStorage.setItem("brandEdit", JSON.stringify(false));
          setLoading(true);
          if (key === "customerBuyer") {
            let roleID;
            response.data.find((el) =>
              el.name === "Enterprise Buyer" ? (roleID = el._id) : null
            );
            let roleObject = {
              _id: roleID,
              name: "Enterprise Buyer",
              type: "user",
            };
            setLoading(false);
            navigate("/customer/brandPageCreation", {
              state: {
                roleObj: roleObject,
                customerType:
                  key === "customerBuyer"
                    ? "companyYesBrand"
                    : "companyYesDeveloper",
              },
            });
          } else if (key === "customerSeller") {
            let roleID;
            response.data.find((el) =>
              el.name === "Enterprise Seller" ? (roleID = el._id) : null
            );
            let roleObject = {
              _id: roleID,
              name: "Enterprise Seller",
              type: "user",
            };
            setLoading(false);
            navigate("/customer/developerPageCreation", {
              state: {
                roleObj: roleObject,
                customerType: "companyYesDeveloper",
              },
            });
          } else if (key === "customerAgent") {
            let roleID;
            response.data.find((el) =>
              el.name === "agent" ? (roleID = el._id) : null
            );
            let roleObject = {
              _id: roleID,
              name: "agent",
              type: "user",
            };
            setLoading(false);
            navigate("/customer/agentPageCreation", {
              state: { roleObj: roleObject, new: true },
            });
          }
        }
      }
    });
  };
  const setContextValue = (tableKey) => {
    let newValue = setTableFilterData(
      storetableName,
      {
        pageNo: 1,
        perPage: 10,
        id: "",
      },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };
  const setTabKey = (k) => {
    setKey(k);
    setContextValue();
    setOffset(1);
    //TODO
    // filterReducerDispatch({
    //   type: "CITY",
    //   value: {
    //     label: "City",
    //     value: "City",
    //   },
    // });

    // filterReducerDispatch({ type: "ACTIVE", value: "" });
    localStorage.setItem("customerTabKey", k);
  };
  useEffect(() => {
    getCityList();
    dispatch(updateBrandId(""));
    dispatch(updateComapanyId(""));
    dispatch(updateCompanyFormStatus("")); // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(updateParentPageData("")); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let customerView =
    loginPermission?.includes(menuId.customerBuyer) ||
    loginPermission?.includes(menuId.customerSeller) ||
    loginPermission?.includes(menuId.customerAgents);

  let addBuyer = key === "customerBuyer" && access?.addBuyerCustomers;
  let addAgent = key === "customerAgent" && access?.addAgentCustomers;
  return (
    <>
      {customerView ? (
        <div>
          <div className="d-flex justify-content-between">
            <NavBar />
            <div className="d-flex " style={{ textAlign: "end" }}>
              <Search onChange={handleSearch} value={search} />
              {key === "customerSeller" ? (
                ""
              ) : (
                <div className="col-md-5 mr-4">
                  <Button
                    onClick={(e) => onClick(e, "add")}
                    // disabled={loading}
                    loading={loading}
                    className="btn-color-primary mobilePosition"
                    disabled={addBuyer || addAgent ? false : true}
                  >
                    {key === "customerBuyer" ? "Add Brand" : "Add Agent"}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="filter-container">
            <div className="pt-3">
              <select
                style={{ width: "100%" }}
                className="form-select filterBGColor "
                key="activeInactiveFilter"
                name="activeInactiveFilter"
                id="activeInactiveFilter"
                onChange={(e) => onchangeActiveFilterKey(e)}
                value={filter?.active}
              >
                <option key="active/inactive">Active/Inactive</option>
                <option key="active" value={"Active"}>
                  Active
                </option>
                <option key="inactive" value={"Inactive"}>
                  Inactive
                </option>
              </select>
            </div>

            <MultiSelect
              width="15%"
              onChange={(val) => {
                onchangeCityFilterKey(val);
              }}
              isMulti={false}
              options={[{ label: "City", value: "City" }, ...cityFilterValues]}
              value={filter?.city}
            />
          </div>
          <Tab
            tabDetails={tabDetails}
            activeKey={key}
            onSelect={(k) => setTabKey(k)}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Customer;
