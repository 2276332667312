import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MainTable from "../../../../components/Table";
import Select from "../../../../components/Form/Select/index";
import Search from "../../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  getData2,
  handleRmDashboardNewReleventCrossAction,
  refreshData,
  switchToUsers,
} from "../../../../api";
import { Row, Col } from "react-bootstrap";
import CrossSign from "../../../../assets/images/CrossSign.svg";
import Loader from "../../../../components/Loader";
import SwitchAccountPopup from "../../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshButton from "../../../../components/RefreshButton";
import { useSelector } from "react-redux";
import { PHYSICALTEAM, PHYSICAL_TEAM } from "../../../../custom/constants";
import Switch from 'react-js-switch';
import {MULTIPLE_SWITCH} from "../../../../custom/constants";

const ListPhysicalTeamRelevancySupplyView = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertyType, setPropertyType] = useState();
  const [transactionType, setTransactionType] = useState();
  const [sort, setSort] = useState();
  const [refLoading, setRefLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  // const newRelevancy = searchParams.get("filter") === "new";
  const newRelevancy = tableType.includes("New");
  let navigate = useNavigate();

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "dsc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  const [search, setSearch] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [switchState, handleSwitchState] = useState(false);

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
        // const brandPOCs = res?.data?.brandPOCs || [];
        // const regionPOCs = res?.data?.regionPOCs || [];
        const combinedArrays = [...companySpecificPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            companyPocs: companySpecificPOCs,
          });
        }
      }
    });
  };

  const manageSwitch = () => {
    let thing = !switchState;
    handleSwitchState(thing);
  };

  const handleSwitchToRelevant = (row, isNew) => {
    const data = {
      customerId: row?.pocId,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = switchState ? process.env.REACT_APP_REVAMP_SWITCH_URL : process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(
          `${openUrl}/${
            res?.data?.token
          }?trd=/profile/current-relevant-demands${
            isNew ? "/New" : ""
          }?propertyId=${row?.propertyId}`
        );
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const handleChangeResponseStatus = async (row) => {
   
    const url = `/property/propertyRelevantRead/${row.propertyDisplayId}`;
   const body = {
    type:PHYSICAL_TEAM
}
    const res = await handleRmDashboardNewReleventCrossAction(url,body);
    if(res?.status) {
      getTableData(false)
    }
    // const url = `/property/updatePropertyResponse/${row._id}`;
    // const res = await handleRmDashboardResponse(url);
    // if(res?.status) {
    //   getTableData(false)
    // }
  }

  let columnsBrand = [
    {
      dataField: "createdAt",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.createdAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text:"Physical Team Name",
      dataField: "pocName",
    },
    {
      text: "Property ID",
      dataField: "propertyDisplayId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              cellContent &&
                navigate("propertyDetailView/" + cellContent, {
                  state: { isNew: newRelevancy },
                });
            }}
          >
            {cellContent}
          </div>
        );
      },
    },
    {
      text: "City",
      dataField: "city",
    },
    {
      text: "Total Relevancy",
      dataField: "count",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${
              cellContent &&
              loginPermission?.includes(menuId?.agentSwitch) &&
              "hyperlinkText"
            }`}
            onClick={(e) => {
              if (loginPermission?.includes(menuId?.agentSwitch)) {
                handleSwitchToRelevant(row, false);
              }
            }}
          >
            {cellContent}
          </div>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newRelevancy ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?._id);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/dashboard/getDashboardRelevantPropertiesForPhyiscalTeam=?pageNo=${offSet}&limit=${perPage}&isNew=${
      newRelevancy ? true : false
    }`;
    if (search.length > 2) {
      url += `&searchTerm=${search.trim()}`;
    }

    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data?.totalData / perPage));
      setTotalCount(data?.totalData);
      setTableData(data?.responseList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  if (newRelevancy) {
    let newRelevancyObj = {
      text: "New Relevancy",
      dataField: "unreadCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${
              cellContent &&
              loginPermission?.includes(menuId?.agentSwitch) &&
              "hyperlinkText"
            }`}
            onClick={(e) => {
              if (loginPermission?.includes(menuId?.agentSwitch)) {
                handleSwitchToRelevant(row, true);
              }
            }}
          >
            {cellContent}
          </div>
        );
      },
    };
    columnsBrand.splice(4, 0, newRelevancyObj);
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="cross"
                src={CrossSign}
                onClick={() => {
                  handleChangeResponseStatus(row)
                }}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(7, 0, ...actionCol);
  }
  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rm",
        formatter: (cellContent, row, rowIndex) => {
          let rmName = row?.rmData?.name;
          return <div>{rmName ? rmName : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(3, 0, ...rmData);
  }
  let tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, propertyType, transactionType, sort]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  useEffect(() => {
    // getBrandList();
    getPropertyType();
  }, []);

  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };

  const refreshRelevancy = async () => {
    const url = `/dashboard/setRelevancyData?type=relevantPropertyForPhysicalTeam`;
    setRefLoading(true);
    let response = await refreshData(url);
    setRefLoading(false);
    if (response?.status) {
      Swal.fire({
        // title: header,
        text: "Please wait! New data will reflect after 5 Min",
        icon: "success",
        timer: 5000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };

  if(loginPermission?.includes(menuId?.agentDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          {MULTIPLE_SWITCH &&
          <div className="Relevancy_switch">
            <Switch onChange={manageSwitch} value={switchState} 
              size={50} color={'rgb(1 81 105)'}
              backgroundColor={{ on: '#f9f9f9', off: '#f9f9f9' }}
              borderColor={{ on: '#f9f9f9', off: '#f9f9f9' }} 
            />
          </div>}
          <div className="Relevancy_Refresh">
            <RefreshButton loading={refLoading} onClick={refreshRelevancy} />
          </div>
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={customerCreatedSortValues}
                  name={"Posted"}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    setOffset(1);
                  }}
                  // isHideSelect
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return (
      <AccessDenied />
    )
  }
};
export default ListPhysicalTeamRelevancySupplyView;
