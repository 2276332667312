import React, { useEffect, useState } from "react";

//css
import '../../subscription.scss'
import Button from "../../../../components/Form/Button/index";
import { putData } from "../../../../api/index";

const AgentEditTable = (props) => {
  let buyerPlanData = props.data;
  const [apiData, setApiData] = useState(buyerPlanData);
  const [reload, setReload] = useState(false);

  useEffect(() => {
     
    apiData.forEach((e) => {
      if (e.submissionAgainstDemand !== "Unlimited") {
        e.submissionEnterNumber = "Enter Number";
      }
      if (e.featuredPositioning !== "No") {
        e.featuredEnterNumber = "Enter Number";
      }
      if (e.propertyOrDemand !== "Unlimited" && e.planName==="Ultra") {
        e.propertyOrDemandEnterNumber = "Enter Number";
      }
    });
  }, []);
  const columnsData = [
    { title: "propertyOrDemand", type: 1 },
    { title: "validityDays", type: 1 },
    { title: "demandMails", type: 2 },
    { title: "submissionAgainstDemand", type: 3 },
    { title: "emailtoSuppliers", type: 2 },
    { title: "contactSuppliers", type: 1 },
    { title: "userDashboard", type: 2 },
    { title: "featuredPositioning", type: 3 },
    { title: "assistedUploading", type: 2 },
    { title: "relationshipManager", type: 2 },
    { title: "discount", type: 1 },
    { title: "subscriptionAmount", type: 1 },
  ];
  const onInputChange = (updatedText, title, data) => {

    apiData.forEach((e) => {
      if (e._id === data._id) {
        if (updatedText === "Enter number") {
          if (title === "submissionAgainstDemand") {
            e[title] = "";
            e.submissionEnterNumber = "Enter Number";
          }
          if (title === "featuredPositioning") {
            e[title] = "";
            e.featuredEnterNumber = "Enter Number";
          }
          if (title === "propertyOrDemand") {
            e[title] = "";
            e.propertyOrDemandEnterNumber = "Enter Number";
          }
        
        } else {
          if (
            title === "submissionAgainstDemand" &&
            updatedText === "Unlimited"
          ) {
            delete e["submissionEnterNumber"];
            e[title] = "";
          }
          if (title === "propertyOrDemand" && updatedText === "Unlimited") {
            delete e["propertyOrDemandEnterNumber"];
            e[title] = "";
          }
          if (title === "featuredPositioning" && updatedText === "No") {
            delete e["featuredEnterNumber"];
            e[title] = "";
          }
          

          e[title] = updatedText;
        }
      }
    });
    setReload(!reload);
    setApiData(apiData);
  };

  const onKeyDown = (e) =>{
    if (
      (e.keyCode !== 8 &&
        e.keyCode !== 9 &&
        e.keyCode !== 0 && e.keyCode !== 37 && e.keyCode !== 38 &&e.keyCode !== 39 &&e.keyCode !== 40 &&
        e.keyCode < 48) ||
      e.keyCode > 57
    ) {
      e.preventDefault();
    }
  }

  const renderInput = (cValue, data) => {
    return (
      <>
        {cValue.title === "validityDays" ? (
          <div style={{ display: "flex" }}>
            <input
            id={`input${cValue.title}${data?.planName}`}
              style={{ width: "100%" }}
              autoComplete={"off"}
              type={"number"}
              min={0}
              className="form-control"
              defaultValue={data[cValue.title]}
              onKeyDown={(e)=> onKeyDown(e)}
              onChange={(e) =>
                onInputChange(e.target.value, cValue.title, data)
              }
            />
            <label
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "20px",
              }}
            >
              Days
            </label>
          </div>
        ) : cValue.title === "discount" ? (
          <div style={{ display: "flex" }}>
            <input
             id={`input${cValue.title}${data?.planName}`}
              style={{ width: "100%" }}
              autoComplete={"off"}
              type={"number"}
              className="form-control"
              min={0}
              max={100}
              defaultValue={data[cValue.title]}
              onKeyDown={(e)=> onKeyDown(e)}
              onChange={(e) =>
                onInputChange(e.target.value, cValue.title, data)
              }
            />
            <label
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "20px",
              }}
            >
              %
            </label>
          </div>
        ) : cValue.title === "subscriptionAmount" ? (
          <div style={{ display: "flex" }}>
            <label
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "20px",
              }}
            >
              ₹
            </label>
            <input
              id={`input${cValue.title}${data?.planName}`}
              style={{ width: "100%" }}
              autoComplete={"off"}
              type={"number"}
              min={0}
              className="form-control"
              defaultValue={data[cValue.title]}
              onKeyDown={(e)=> onKeyDown(e)}
              onChange={(e) =>
                    onInputChange(e.target.value, cValue.title, data)
              }
            />
          </div>
        ) : (
          <input
          id={`input${cValue.title}${data?.planName}`}
            className="form-control"
            value={data[cValue.title]}
            onKeyDown={(e)=> onKeyDown(e)}
            onChange={(e) => onInputChange(e.target.value, cValue.title, data)}
            autoComplete={"off"}
            min={0}
          />
        )}
      </>
    );
  };
  const renderInputDropdown = (cValue, data) => {
   
  
     
    return (
      
        <div style={{ display: "flex",flexDirection:"column" , rowGap: "10px" }}>
          <select
            style={{ width: "100%" }}
            value={
              cValue?.title === "submissionAgainstDemand" &&
              data["submissionEnterNumber"]
                ? data["submissionEnterNumber"]
                : cValue?.title === "featuredPositioning" &&
                  data["featuredEnterNumber"]
                ? data["featuredEnterNumber"]
                : cValue?.title === "propertyOrDemand" &&
                  data["propertyOrDemandEnterNumber"]
                ? data["propertyOrDemandEnterNumber"]
                : data[cValue.title]
            }
            onChange={(e) => onInputChange(e.target.value, cValue.title, data)}
            className="form-select"
          >
            <option value={"Enter number"}>Enter number</option>
            <option value={cValue?.title === "featuredPositioning" ? "No" : "Unlimited"}>{cValue?.title === "featuredPositioning" ? "No" : "Unlimited"}</option>
          </select>

          <input
            disabled={
              cValue?.title === "submissionAgainstDemand"
              && data["submissionEnterNumber"]
                  ? false
                : cValue?.title === "featuredPositioning"
                && data["featuredEnterNumber"]
           
                  ? false
                  :(cValue?.title==="propertyOrDemand" &&  data["propertyOrDemandEnterNumber"])?false:true
            }
            id={`input${cValue.title}${data?.planName}`}
            style={{ width: "100%" }}
            autoComplete={"off"}
            type={"number"}
            min={0}
            className="form-control"
            value={
              data[cValue.title] === "Unlimited" ? "" : data[cValue.title]
            }
            onKeyDown={(e)=> onKeyDown(e)}
            onChange={(e) => onInputChange(e.target.value, cValue.title, data)}
          />
         
        </div>
      
    );
  };

  const renderDropdown = (cValue, data) => {
    return (
      <>
        {cValue.title === "demandMails" ? (
          <select
            value={data[cValue.title]}
            onChange={(e) => onInputChange(e.target.value, cValue.title, data)}
            className="form-select"
          >
            <option value={"Instant"}>Instant</option>
            <option value={"Daily"}>Daily</option>
            <option value={"Monthly"}>Monthly</option>
            <option value={"Weekly"}>Weekly</option>
          </select>
        ) : (
          <select
            value={data[cValue.title]}
            onChange={(e) => onInputChange(e.target.value, cValue.title, data)}
            className="form-select"
          >
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
        )}
      </>
    );
  };

  const renderCell = (column, data) => {
    switch (column.type) {
      case 1:
        return renderInput(column, data);
      case 2:
        return renderDropdown(column, data);
      case 3:
        return renderInputDropdown(column, data);
      default:
    }
  };

  //camel case conversion
  const camelCaseConversion = (value) => {
    return value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };

  const onClickSave = () => {
    apiData.forEach((val) => {
      delete val.isActive;
      delete val.type;
      delete val.discountAmount;
      delete val.submissionEnterNumber;
      delete val.featuredEnterNumber;
      delete val.propertyOrDemandEnterNumber
    });

    let updatedata = {
      plans: apiData,
    };

    let url = "/subscription";
    putData(url, {}, updatedata).then((response) => {
      if (response.status) {
        props.editState(false);
      } else {
        apiData.forEach((val) => {
          if(val?.featuredPositioning !== "No"){
            val.featuredEnterNumber = "Enter number";
          }
          if(val?.submissionAgainstDemand !== "Unlimited"){
            val.submissionEnterNumber = "Enter number";
          }
        });
      }
    });
  };
  return (
    <div className="subscription-container">
      <div className="customTable">
        <div className="react-bootstrap-table table-responsive editTable-content customScroll">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th>Benefits</th>
                {apiData.map((k, i) => {
                  return <th key={i}>{k.planName}</th>;
                })}
              </tr>
            </thead>

            <tbody className="tableBody" style={{ verticalAlign: "middle" }}>
              {columnsData.map((k, index) =>{
                return (
               

                <tr key={index}>
                  <th className="benefits-subscription">
                    {k.title === "propertyOrDemand"
                      ? "Listings"
                      : k.title === "featuredPositioning"
                      ? "Featured property"
                      : k.title === "subscriptionAmount"
                      ? "Subscription Amount (Excluding GST)"
                      : k.title === "emailtoSuppliers"
                      ? "Email to Suppliers"
                      : k.title === "contactSuppliers"
                      ? "Contact Suppliers Limit"
                      : camelCaseConversion(k.title)}
                  </th>
                  {apiData.map((j, index1) => {

                 let value =(j.planName==="Ultra" && k.title === "propertyOrDemand")?{...k,type:3}:k

                    return (
                      <React.Fragment key={index1}>
                        <td>{renderCell(value, j)}</td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              )
              } 
              
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="btn-container p-5">
        <Button onClick={(e) => onClickSave(e)} className="btn-color-primary">
          Save
        </Button>
      </div>
    </div>
  );
};
export default AgentEditTable;
